import {
  FETCH_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  REPLACE_CLIENT,
} from "./types";

export const fetchingClients = (clients) => (dispatch) => {
  dispatch({ type: FETCH_CLIENTS, payload: clients });
};

export const updatingClients = (client) => (dispatch) => {
  dispatch({ type: UPDATE_CLIENT, payload: client });
};

export const replacingClients = (client) => (dispatch) => {
  dispatch({ type: REPLACE_CLIENT, payload: client });
};

export const addingClient = (client) => (dispatch) => {
  dispatch({ type: ADD_CLIENT, payload: client });
};
