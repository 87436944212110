import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingMode,
  updatingMode,
  deletingMode,
} from "../../actions/modeActions";
import swal from "sweetalert";
import { app } from "../../constants/config";

const AddModes = (props) => {
  const [Modes, setModes] = useState([]);
  const [Saving, setSaving] = useState(false);

  const updateModes = (value, index, field) => {
    Modes[index] = { ...Modes[index], [field]: value };
    setModes([...Modes]);
  };

  const RemoveMode = (index) => {
    let newModes = [...Modes];
    newModes.splice(index, 1);
    setModes([...newModes]);
  };

  const AddModenput = () => {
    setModes([...Modes, { name: "", linker: Date.now() }]);
  };

  const SubmitModes = (Mode) => {
    setSaving(true);
    fetch(`${app}/mode/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Mode,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingMode(details);
          setModes([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveModes = () => {
    Modes.forEach((Mode, index) => {
      if (/\S/.test(Mode)) {
        SubmitModes({
          ...Mode,
        });
      }
    });
  };

  const ModeOutput = Modes.map((Mode, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveMode(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Name</label>
              <input
                placeholder="Enter Mode Name"
                value={Mode.name}
                onChange={(e) => updateModes(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      {ModeOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Modes.length < 1 ? (
            <button
              className="btn-sm btn-info rounded-pill"
              onClick={AddModenput}
            >
              Add Mode
            </button>
          ) : Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveModes}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Landlords: state.Landlord.Landlords,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingMode,
  updatingMode,
  deletingMode,
})(AddModes);
