import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingInvoice, updatingInvoice } from "../../actions/invoiceActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayInvoices = (props) => {
  const [ActiveInvoice, setActiveInvoice] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const SubmitPayment = () => {
    setEditting(true);
    fetch(`${app}/invoice/pay`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveInvoice,
        linker: Date.now(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setEditting(false);
        if (details.state != 2) {
          $("#payInvoice").modal("hide");
          props.updatingInvoice(details);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setEditting(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const saveEdittedInvoice = () => {
    if (/\S/.test(ActiveInvoice.name)) {
      setEditting(true);
      fetch(`${app}/invoice/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveInvoice,
          InvoiceId: ActiveInvoice.id,
          houses_id: GetTenant(ActiveInvoice.tenants_id).houses_id,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editInvoice").modal("hide");
          if (details.code != 2) {
            props.updatingInvoice(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0 };
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const saveDeletedInvoice = () => {
    setDeleting(true);
    fetch(`${app}/invoice/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveInvoice,
        InvoiceId: ActiveInvoice.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteInvoice").modal("hide");
        if ((details.code = 2)) {
          props.deletingInvoice(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const InvoicesDisplay = props.Invoices.map((Invoice) => (
    <tr>
      <td className="capitalize">{Invoice.for}</td>
      <td>{Invoice.month}</td>
      <td>{GetTenant(Invoice.tenants_id).name}</td>
      <td>{GetHouse(Invoice.houses_id).name}</td>
      <td className="text-primary">{Invoice.amount}</td>
      <td className="text-success">
        {Invoice.payments
          ? Invoice.payments.reduce((a, b) => +a + +b.amount, 0)
          : 0}
      </td>
      <td className="text-danger">
        {Invoice.amount -
          (Invoice.payments
            ? Invoice.payments.reduce((a, b) => +a + +b.amount, 0)
            : 0)}
      </td>
      <td>{Invoice.bankerEmail}</td>
      <td>
        {
          new Date(parseInt(Invoice.linker))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")
            .split(" ")[0]
        }
      </td>
      <td>{Invoice.details}</td>
      <td>
        {" "}
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveInvoice({ ...Invoice, mode: "cash" })}
          data-toggle="modal"
          data-target="#payInvoice"
        >
          Pay
        </button>
      </td>
      <td>
        {" "}
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveInvoice({ ...Invoice })}
          data-toggle="modal"
          data-target="#editInvoice"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveInvoice({ ...Invoice })}
          data-toggle="modal"
          data-target="#deleteInvoice"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column" colSpan={1}>
                Total
              </th>
              <th scope="column" colSpan={2}>
                Invoice
              </th>

              <th scope="column" colSpan={2}>
                {props.Invoices.reduce((a, b) => +a + +b.amount, 0)}
              </th>
              <th scope="column" colSpan={2}>
                Paid
              </th>

              <th scope="column" colSpan={2}>
                {props.Invoices.map((Invoice) =>
                  Invoice.payments
                    ? Invoice.payments.reduce((a, b) => +a + +b.amount, 0)
                    : 0
                ).reduce((a, b) => +a + +b, 0)}
              </th>
              <th scope="column" colSpan={2}>
                Bal
              </th>

              <th scope="column" colSpan={2}>
                {props.Invoices.reduce((a, b) => +a + +b.amount, 0) -
                  props.Invoices.map((Invoice) =>
                    Invoice.payments
                      ? Invoice.payments.reduce((a, b) => +a + +b.amount, 0)
                      : 0
                  ).reduce((a, b) => +a + +b, 0)}
              </th>
            </tr>
            <tr>
              <th scope="column">Name</th>
              <th scope="column">Month</th>
              <th scope="column">Tenant</th>
              <th scope="column">House</th>
              <th scope="column">Amount</th>
              <th scope="column">Paid</th>
              <th scope="column">Bal</th>
              <th scope="column">By</th>
              <th scope="column">Date</th>
              <th scope="column">Details</th>
              <th scope="column">Action</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {InvoicesDisplay}
        </table>
      </div>
      <div
        id="payInvoice"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Pay {ActiveInvoice.for} {ActiveInvoice.month} Invoice
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Amount</label>
                  <input
                    placeholder="Enter Amount Paid"
                    value={ActiveInvoice.amount}
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        amount:
                          e.target.value.slice(-1) == "-" ||
                          e.target.value.slice(-1) == "." ||
                          !isNaN(e.target.value.slice(-1))
                            ? e.target.value
                            : e.target.value.slice(0, -1),
                      })
                    }
                    className="rounded-pill form-control"
                  />
                  <label>Payment Mode</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        mode: e.target.value,
                      })
                    }
                  >
                    <option value="cash">Select Payment Mode</option>
                    {props.Modes.map((mode) => (
                      <option value={mode.name}>{mode.name}</option>
                    ))}
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                    <option value="electronic">Electronic/M-pesa</option>
                  </select>{" "}
                  <div>
                    <label>Payment Details :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Payment Details"
                      value={ActiveInvoice.details}
                      onChange={(e) =>
                        setActiveInvoice({
                          ...ActiveInvoice,
                          details: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => SubmitPayment()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="editInvoice"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveInvoice.for} {ActiveInvoice.month} Invoice
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Tenant To Invoice</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        tenants_id: e.target.value,
                      })
                    }
                  >
                    <option value={0}>Select Tenant</option>
                    {props.Tenants.map((tenant) => (
                      <option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </select>

                  <label>Invoice For</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        for: e.target.value,
                      })
                    }
                  >
                    <option value="rent">Select Particular</option>
                    <option value="deposit">Deposit</option>
                    <option value="rent">Rent</option>
                    <option value="water">Water Bill</option>
                    <option value="electricity">Electricity Bill</option>
                    <option value="expenses">Other Expenses</option>
                  </select>

                  <label>For Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        month: e.target.value,
                      })
                    }
                  />

                  <label>Amount</label>
                  <input
                    placeholder="Enter Amount To Invoice"
                    value={ActiveInvoice.amount}
                    onChange={(e) =>
                      setActiveInvoice({
                        ...ActiveInvoice,
                        amount:
                          e.target.value.slice(-1) == "-" ||
                          e.target.value.slice(-1) == "." ||
                          !isNaN(e.target.value.slice(-1))
                            ? e.target.value
                            : e.target.value.slice(0, -1),
                      })
                    }
                    className="rounded-pill form-control"
                  />

                  <div>
                    <label>Invoice Details :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Invoice Details"
                      value={ActiveInvoice.details}
                      onChange={(e) =>
                        setActiveInvoice({
                          ...ActiveInvoice,
                          details: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedInvoice()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteInvoice"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveInvoice.for} {ActiveInvoice.month} Invoice
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedInvoice()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Invoices: state.Invoice.Invoices,
  Tenants: state.Tenant.Tenants,
  Houses: state.House.Houses,
  Modes: state.Mode.Modes,
});

export default connect(mapStateToProps, {
  deletingInvoice,
  updatingInvoice,
})(withRouter(DisplayInvoices));
