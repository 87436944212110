import React, { useEffect } from "react";
import { connect } from "react-redux";
import DisplayLandlords from "./DisplayLandlords";
import { fetchingLandlords } from "../../actions/LandlordActions";
import NoLandlords from "./NoLandlords";
import AddLandlords from "./AddLandlords";
import { app } from "../../constants/config";

const Landlords = (props) => {
  useEffect(() => {
    FetchLandlords();
  }, []);

  const FetchLandlords = () => {
    fetch(`${app}/landlord/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getTime(),
        toDate:
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            ).getDate(),
        month: `${new Date().getFullYear()}-${(
          "0" +
          (new Date().getMonth() + 1)
        ).slice(-2)}`,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 2) {
        } else {
          props.fetchingLandlords(details);
        }
      })
      .catch((err) => {});
  };

  const Status =
    props.Landlords.length > 0 ? <DisplayLandlords /> : <NoLandlords />;

  return (
    <>
      <AddLandlords></AddLandlords>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Landlords: state.Landlord.Landlords,
  banker: state.auth.banker,
});

export default connect(mapStateToProps, { fetchingLandlords })(Landlords);
