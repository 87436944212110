import React from "react";
import { withRouter } from "react-router-dom";
import Download from "../Download/Download";

const Details = (props) => {
  const FilterBal = (houseId) => {
    if (props.summary.length == 5) {
      let FullBal =
        props.invoices
          .filter((invoice) => invoice.houses_id == houseId)
          .reduce((a, b) => +a + +b.amount, 0) -
        props.payments
          .filter((payment) => payment.houses_id == houseId)
          .reduce((a, b) => +a + +b.amount, 0);
      return props.bal == "all"
        ? true
        : props.bal == "bal"
        ? FullBal > 0
        : FullBal < 1;
    } else {
      let FullBal =
        props.invoices
          .filter(
            (invoice) =>
              invoice.for == props.summary[0] && invoice.houses_id == houseId
          )
          .reduce((a, b) => +a + +b.amount, 0) -
        props.payments
          .filter(
            (payment) =>
              payment.for == props.summary[0] && payment.houses_id == houseId
          )
          .reduce((a, b) => +a + +b.amount, 0);
      return props.bal == "all"
        ? true
        : props.bal == "bal"
        ? FullBal > 0
        : FullBal < 1;
    }
  };

  const Output = props.houses.map((house) => {
    return FilterBal(house.id) ? (
      <tr>
        <td>{house.name}</td>
        {props.summary.map((bill) => {
          return (
            <>
              <td className="text-primary">
                {props.invoices
                  .filter(
                    (invoice) =>
                      invoice.for == bill && invoice.houses_id == house.id
                  )
                  .reduce((a, b) => +a + +b.amount, 0)}
              </td>
              <td className="text-success">
                {props.payments
                  .filter(
                    (payment) =>
                      payment.for == bill && payment.houses_id == house.id
                  )
                  .reduce((a, b) => +a + +b.amount, 0)}
              </td>
              <td className="text-danger">
                {props.invoices
                  .filter(
                    (invoice) =>
                      invoice.for == bill && invoice.houses_id == house.id
                  )
                  .reduce((a, b) => +a + +b.amount, 0) -
                  props.payments
                    .filter(
                      (payment) =>
                        payment.for == bill && payment.houses_id == house.id
                    )
                    .reduce((a, b) => +a + +b.amount, 0)}
              </td>
            </>
          );
        })}
        <td>
          {house.tenants.length > 0
            ? `${house.tenants[house.tenants.length - 1].name}`
            : "none"}
        </td>
        <td>
          {house.tenants.length > 0
            ? `${house.tenants[house.tenants.length - 1].contact}`
            : "none"}
        </td>
        <td></td>
      </tr>
    ) : null;
  });

  return (
    <div>
      <div id="rental">
        <p className="text-center h3 text-capitalize">
          {props.summary.length > 1 ? "Full" : props.summary[0]} {props.bal}{" "}
          Report For {props.building} {props.month}
        </p>
        <div id="rentalDetails">
          <table className="table table-bordered table-condensed table-sm">
            <thead>
              <tr className="table-primary">
                <th scope="column">House</th>

                {props.summary.map((item) => (
                  <th scope="column" colSpan={3} className="text-capitalize">
                    {item}
                  </th>
                ))}

                <th scope="column">Tenant Name</th>
                <th scope="column">Tenant Contact</th>
                <th scope="column">
                  <span style={{ opacity: 0 }}>Resizer</span>
                </th>
              </tr>
              <tr className="table-secondary">
                <th scope="column"></th>

                {props.summary.map((item) => (
                  <>
                    <th scope="column">Inv</th>
                    <th scope="column">Paid</th>
                    <th scope="column">Bal</th>
                  </>
                ))}

                <th scope="column"></th>
                <th scope="column"></th>
                <th scope="column"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <th scope="column">Total</th>

                {props.summary.map((bill) => {
                  return (
                    <>
                      <th scope="column" className="text-primary">
                        {props.invoices
                          .filter(
                            (invoice) =>
                              invoice.for == bill &&
                              props.houses
                                .map((specific) => `${specific.id}`)
                                .includes(invoice.houses_id) &&
                              FilterBal(invoice.houses_id)
                          )
                          .reduce((a, b) => +a + +b.amount, 0)}
                      </th>
                      <th scope="column" className="text-success">
                        {props.payments
                          .filter(
                            (payment) =>
                              payment.for == bill &&
                              props.houses
                                .map((specific) => `${specific.id}`)
                                .includes(payment.houses_id) &&
                              FilterBal(payment.houses_id)
                          )
                          .reduce((a, b) => +a + +b.amount, 0)}
                      </th>
                      <th scope="column" className="text-danger">
                        {props.invoices
                          .filter(
                            (invoice) =>
                              invoice.for == bill &&
                              props.houses
                                .map((specific) => `${specific.id}`)
                                .includes(invoice.houses_id) &&
                              FilterBal(invoice.houses_id)
                          )
                          .reduce((a, b) => +a + +b.amount, 0) -
                          props.payments
                            .filter(
                              (payment) =>
                                payment.for == bill &&
                                props.houses
                                  .map((specific) => `${specific.id}`)
                                  .includes(payment.houses_id) &&
                                FilterBal(payment.houses_id)
                            )
                            .reduce((a, b) => +a + +b.amount, 0)}
                      </th>
                    </>
                  );
                })}
                <th scope="column"></th>
                <th scope="column"></th>
                <th scope="column"></th>
              </tr>

              {Output}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Download
          rootElementId={`rental`}
          downloadFileName={`${
            props.summary.length > 1 ? "full" : props.summary[0]
          }-${props.bal}-report-for-${props.building}-${props.month}`}
        ></Download>
      </div>
    </div>
  );
};

export default withRouter(Details);
