import { ADD_VISITOR } from "../actions/types";

const initialState = {
  visitor: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_VISITOR:
      return { ...state, visitor: action.payload };
    default:
      return state;
  }
};
