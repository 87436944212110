import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingPayments } from "../../actions/paymentActions";
import DisplayPayments from "../Payments/DisplayPayments";
import NoPayments from "../Payments/NoPayments";
import AddPayments from "../Payments/AddPayments";

const TenantPayments = (props) => {
  useEffect(() => {
    FetchPayments();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState(false);

  const FetchPayments = () => {
    setLoading(true);
    fetch(`${app}/payment/tenant`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        tenantId: window.location.href.split("/").pop(),
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingPayments(details);
        } else {
          props.fetchingPayments([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingPayments([]);
        setLoading(false);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0 };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Payments.length > 0 ? (
    <DisplayPayments />
  ) : (
    <NoPayments />
  );

  return (
    <>
      <p className="h6 text-center text-primary">
        <strong>
          {GetTenant(window.location.href.split("/").pop()).name} Payments
        </strong>
      </p>
      <AddPayments
        from={"tenants"}
        tenants_id={window.location.href.split("/").pop()}
      ></AddPayments>
      {Status}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Payments: state.Payment.Payments,
  Tenants: state.Tenant.Tenants,
});

export default connect(mapStateToProps, {
  fetchingPayments,
})(TenantPayments);
