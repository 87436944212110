import React from "react";
import logo from "../../images/zidi-capital-sacco-logo.jfif";

const Privacy = () => {
  return (
    <div>
      <div className="bg-primary headerContainer">
        <div className="row bankHeader justify-content-center">
          <div className="col-12 text-center">
            <img
              src={logo}
              className="float-left logo-image"
              alt="Zidi Capital Sacco-logo"
            />
            <span className="bankTitle text-capitalize">
              Zidi Capital Sacco
            </span>
            <br />
            <span className="bankSubtitle text-capitalize">
              Inspire And Empower
            </span>
            <div className="float-right mr-2 mb-2 text-light">
              <span>Privacy Policy Jan 2022</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center content">
        <div className="col-12 col-md-6">
          <h6 className="text-primary">Information Gathering and Usage</h6>
          <p>
            When you register in Zidi Capital Sacco we ask for information such
            as your name and e-mail address. Zidi Capital Sacco uses this
            information only for authentication purposes to our service
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <h6 className="text-primary">Information Storage</h6>
          <p>
            Zidi Capital Sacco uses third party services (truehost) to provide
            the necessary hardware, software, networking, storage, and related
            technology required to run Zidi Capital Sacco
          </p>

          <p>
            Although Zidi Capital Sacco owns the code, databases, and all rights
            to the Zidi Capital Sacco platform and services, you retain all
            rights to your data and the right to ask that we correct, amend, or
            delete the personal information we hold about you. An individual who
            seeks access, or who seeks to correct, amend, or delete inaccurate
            data should direct their query to contact@zidicapitalsacco.com Any
            requests for alteration of data must come from the email associated
            with that Zidi Capital Sacco account
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <h6 className="text-primary">Disclosure</h6>
          <p>
            Zidi Capital Sacco may disclose personally identifiable information
            under special circumstances, such as in response to lawful requests
            by public authorities, including to meet national security or law
            enforcement requirements
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <em className="text-primary">
            Any Changes to this policy shall be communicated to you through the
            email address registered at Zidi Capital Sacco
          </em>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
