import {
  ADD_STAFF,
  FETCH_STAFFS,
  UPDATE_STAFF,
  REPLACE_STAFF
} from "../actions/types";

const initialState = {
  staffs: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFFS:
      return { ...state, staffs: action.payload };

    case ADD_STAFF:
      return { ...state, staffs: [action.payload, ...state.staffs] };

    case UPDATE_STAFF:
      var newStaffs = [...state.staffs];
      var oldStaff = newStaffs.find(
        staff => staff.linker == action.payload.linker
      );
      var Index = newStaffs.indexOf(oldStaff);
      newStaffs.splice(Index, 1, action.payload);
      return {
        ...state,
        staffs: [...newStaffs]
      };

    case REPLACE_STAFF:
      var newStaffs = [...state.staffs];
      var oldStaff = newStaffs.find(
        staff => staff.linker == action.payload.linker
      );
      var Index = newStaffs.indexOf(oldStaff);
      newStaffs.splice(Index, 1, action.payload);

      return {
        ...state,
        staffs: [...newStaffs]
      };

    default:
      return state;
  }
};
