import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingTenant,
  updatingTenant,
  deletingTenant,
} from "../../actions/tenantActions";
import swal from "sweetalert";
import { app } from "../../constants/config";

const AddTenants = (props) => {
  const [Tenants, setTenants] = useState([]);
  const [Saving, setSaving] = useState(false);

  const updateTenants = (value, index, field) => {
    Tenants[index] = { ...Tenants[index], [field]: value };
    setTenants([...Tenants]);
  };

  const RemoveTenant = (index) => {
    let newTenants = [...Tenants];
    newTenants.splice(index, 1);
    setTenants([...newTenants]);
  };

  const AddTenantnput = () => {
    setTenants([...Tenants, {}]);
  };

  const SubmitTenants = (Tenant) => {
    if (Tenant.houses_id == 0) {
      swal({
        title: "Select A House",
        text: "Tenant Must Have A House",
        icon: "error",
        timer: 3000,
      });
    }
    setSaving(true);
    fetch(`${app}/tenant/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Tenant,
        linker: Date.now(),
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingTenant(details);
          setTenants([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveTenants = () => {
    Tenants.forEach((Tenant, index) => {
      if (/\S/.test(Tenant)) {
        SubmitTenants({
          ...Tenant,
        });
      }
    });
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const FilterHouses =
    props.banker.credentials.length > 0
      ? props.banker.credentials[0].building == "all"
        ? props.Houses.filter(
            (House) =>
              House.tenants.map((tenant) => tenant.status).includes("1") ==
              false
          )
        : props.Houses.filter(
            (House) =>
              House.tenants.map((tenant) => tenant.status).includes("1") ==
                false &&
              StaffBuildings(props.banker.credentials[0].building).includes(
                parseInt(House.buildings_id)
              )
          )
      : props.Houses.filter(
          (House) =>
            House.tenants.map((tenant) => tenant.status).includes("1") == false
        );

  const TenantOutput = Tenants.map((Tenant, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveTenant(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Tenant House</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) =>
                  updateTenants(e.target.value, index, "houses_id")
                }
              >
                <option value={0}>Select Tenant House</option>
                {FilterHouses.map((house) => (
                  <option key={house.id} value={house.id}>
                    {house.name}
                  </option>
                ))}
              </select>

              <label>Tenant Name</label>
              <input
                placeholder="Enter Tenant Name"
                value={Tenant.name}
                onChange={(e) => updateTenants(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />

              <label>Tenant Id No</label>
              <input
                placeholder="Enter Tenant IdNo"
                value={Tenant.idno}
                onChange={(e) => updateTenants(e.target.value, index, "idno")}
                className="rounded-pill form-control"
              />

              <label>Tenant Contact</label>
              <input
                placeholder="Enter Tenant Contact"
                value={Tenant.contact}
                onChange={(e) =>
                  updateTenants(e.target.value, index, "contact")
                }
                className="rounded-pill form-control"
              />

              <label className="mt-2">Tenant Other Details</label>
              <textarea
                placeholder="Enter Tenant Details"
                value={Tenant.details}
                onChange={(e) =>
                  updateTenants(e.target.value, index, "details")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      {TenantOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Tenants.length < 1 ? (
            <button
              className="btn-sm btn-info rounded-pill"
              onClick={AddTenantnput}
            >
              Add Tenant
            </button>
          ) : Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveTenants}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Houses: state.House.Houses,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingTenant,
  updatingTenant,
  deletingTenant,
})(AddTenants);
