import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingMode, updatingMode } from "../../actions/modeActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayModes = (props) => {
  const [ActiveMode, setActiveMode] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const saveEdittedMode = () => {
    if (/\S/.test(ActiveMode.name)) {
      setEditting(true);
      fetch(`${app}/mode/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveMode,
          ModeId: ActiveMode.id,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editMode").modal("hide");
          if (details.code != 2) {
            props.updatingMode(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const saveDeletedMode = () => {
    setDeleting(true);
    fetch(`${app}/mode/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveMode,
        ModeId: ActiveMode.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteMode").modal("hide");
        if ((details.code = 2)) {
          props.deletingMode(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const ModesDisplay = props.Modes.map((Mode) => (
    <tr>
      <td>{Mode.name}</td>
      <td>
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveMode({ ...Mode })}
          data-toggle="modal"
          data-target="#editMode"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveMode({ ...Mode })}
          data-toggle="modal"
          data-target="#deleteMode"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column">Payment Mode</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {ModesDisplay}
        </table>
      </div>
      <div
        id="editMode"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveMode.name} Mode
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <div>
                    <label>Mode Name :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Mode Name"
                      value={ActiveMode.name}
                      onChange={(e) =>
                        setActiveMode({
                          ...ActiveMode,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedMode()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteMode"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveMode.name} Mode
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedMode()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Modes: state.Mode.Modes,
  Landlords: state.Landlord.Landlords,
});

export default connect(mapStateToProps, {
  deletingMode,
  updatingMode,
})(withRouter(DisplayModes));
