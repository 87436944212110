import {
  ADD_LANDLORD,
  FETCH_LANDLORDS,
  UPDATE_LANDLORD,
  DELETE_LANDLORD,
} from "./types";

export const fetchingLandlords = (landlords) => (dispatch) => {
  dispatch({ type: FETCH_LANDLORDS, payload: landlords });
};

export const addingLandlord = (landlord) => (dispatch) => {
  dispatch({ type: ADD_LANDLORD, payload: landlord });
};

export const updatingLandlord = (landlord) => (dispatch) => {
  dispatch({ type: UPDATE_LANDLORD, payload: landlord });
};

export const deletingLandlord = (landlordId) => (dispatch) => {
  dispatch({ type: DELETE_LANDLORD, payload: landlordId });
};
