import React from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNavItem = (props) => {
  const Navigate = (url) => {
    props.history.push(`/portal/${url}`);
    props.sideBarToggle();
  };

  return (
    <div
      className="bg-secondary"
      style={{ cursor: "pointer" }}
      onClick={() => Navigate(props.url)}
    >
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon
          className="mx-2 h1 text-light pt-2"
          icon={props.icon}
          style={{ fontSize: "80px" }}
        />
      </div>
      <div className="bg-dark">
        <p className="text-light text-center h5 py-2">
          {props.count} {props.name}
        </p>
      </div>
    </div>
  );
};

export default withRouter(SideNavItem);
