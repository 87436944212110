import {
  ADD_REPAYMENT,
  FETCH_REPAYMENTS,
  UPDATE_REPAYMENT,
  DELETE_REPAYMENT,
} from "../actions/types";

const initialState = {
  Repayments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPAYMENTS:
      return { ...state, Repayments: action.payload };

    case ADD_REPAYMENT:
      return { ...state, Repayments: [action.payload, ...state.Repayments] };

    case UPDATE_REPAYMENT:
      var newRepayments = [...state.Repayments];
      var oldBranch = newRepayments.find(
        (repayment) => repayment.linker == action.payload.linker
      );
      var Index = newRepayments.indexOf(oldBranch);
      newRepayments.splice(Index, 1, action.payload);
      return {
        ...state,
        Repayments: [...newRepayments],
      };
    case DELETE_REPAYMENT:
      var newRepayments = [...state.Repayments];

      return {
        ...state,
        Repayments: [
          ...newRepayments.filter(
            (repayment) => repayment.linker != action.payload
          ),
        ],
      };

    default:
      return state;
  }
};
