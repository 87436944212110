import {
  ADD_INVOICE,
  FETCH_INVOICES,
  UPDATE_INVOICE,
  DELETE_INVOICE,
} from "../actions/types";

const initialState = {
  Invoices: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return { ...state, Invoices: action.payload };

    case ADD_INVOICE:
      return { ...state, Invoices: [action.payload, ...state.Invoices] };

    case UPDATE_INVOICE:
      var newInvoices = [...state.Invoices];
      var oldBranch = newInvoices.find(
        (invoice) => invoice.linker == action.payload.linker
      );
      var Index = newInvoices.indexOf(oldBranch);
      newInvoices.splice(Index, 1, action.payload);
      return {
        ...state,
        Invoices: [...newInvoices],
      };
    case DELETE_INVOICE:
      var newInvoices = [...state.Invoices];

      return {
        ...state,
        Invoices: [
          ...newInvoices.filter((invoice) => invoice.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
