import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faUserLock,
  faTachometerAlt,
  faBuilding,
  faHouseUser,
  faHouseDamage,
  faUsers,
  faMoneyCheck,
  faReceipt,
  faMoneyBill,
  faMoneyCheckAlt,
  faArchive,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import SideNavItem from "./SideNavItem";

const InstitutionNav = (props) => {
  return (
    <>
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li className="list-group-item bg-primary navItem m-0 p-0">
            <p
              style={{ cursor: "pointer" }}
              onClick={async () => {
                props.history.push("/portal");
              }}
            >
              {" "}
              <FontAwesomeIcon className="mx-2" icon={faTachometerAlt} />
              DashBoard
            </p>
          </li>
          {props.banker.credentials
            .map((credential) => credential.admin)
            .includes("1") ? (
            <SideNavItem
              url={"staffs"}
              name="Staffs"
              sideBarToggle={props.sideBarToggle}
              icon={faUserLock}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.admin)
            .includes("1") ? (
            <SideNavItem
              url={"modes"}
              name="Payment Modes"
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyCheckAlt}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.landlords)
            .includes("1") ? (
            <SideNavItem
              url={"landlords"}
              name="Landlords"
              sideBarToggle={props.sideBarToggle}
              icon={faHouseUser}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.buildings)
            .includes("1") ? (
            <SideNavItem
              url={"buildings"}
              name="Buildings"
              sideBarToggle={props.sideBarToggle}
              icon={faBuilding}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.houses)
            .includes("1") ? (
            <SideNavItem
              url={"houses"}
              name="Houses"
              sideBarToggle={props.sideBarToggle}
              icon={faHouseDamage}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.tenants)
            .includes("1") ? (
            <SideNavItem
              url={"tenants"}
              name="Tenants"
              sideBarToggle={props.sideBarToggle}
              icon={faUsers}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.expenses)
            .includes("1") ? (
            <SideNavItem
              url={"expenses"}
              name="Expenses"
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyCheck}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.expenses)
            .includes("1") ? (
            <SideNavItem
              url={"invoices"}
              name="Invoices"
              sideBarToggle={props.sideBarToggle}
              icon={faReceipt}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.admin)
            .includes("1") ? (
            <SideNavItem
              url={"payments"}
              name="Tenants Payments"
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyBill}
            ></SideNavItem>
          ) : null}
          {props.banker.credentials
            .map((credential) => credential.reports)
            .includes("1") ? (
            <SideNavItem
              url={"rental"}
              name="Rental Report"
              sideBarToggle={props.sideBarToggle}
              icon={faArchive}
            ></SideNavItem>
          ) : null}

          {props.banker.credentials
            .map((credential) => credential.expenses)
            .includes("1") ? (
            <SideNavItem
              url={"financial"}
              name="Financial Report"
              sideBarToggle={props.sideBarToggle}
              icon={faBook}
            ></SideNavItem>
          ) : null}

          <li className="list-group-item bg-primary m-0 p-0">
            <p
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await window.localStorage.clear();
                props.history.push("/");
              }}
            >
              {" "}
              <FontAwesomeIcon className="mx-2" icon={faPowerOff} />
              LogOut
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  viewed: state.viewed.viewed,
});

export default connect(mapStateToProps, {})(withRouter(InstitutionNav));
