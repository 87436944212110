import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingInvoice,
  updatingInvoice,
  deletingInvoice,
  fetchingInvoices,
} from "../../actions/invoiceActions";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";
import { withRouter } from "react-router-dom";

const AddInvoices = (props) => {
  const [Invoices, setInvoices] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");

  const FetchInvoices = () => {
    setLoading(true);
    fetch(`${app}/invoice/month`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        month: Month,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 2) {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        } else {
          props.fetchingInvoices(details);
          $("#fetchInvoices").modal("hide");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const updateInvoices = (value, index, field) => {
    Invoices[index] = { ...Invoices[index], [field]: value };
    setInvoices([...Invoices]);
  };

  const RemoveInvoice = (index) => {
    let newInvoices = [...Invoices];
    newInvoices.splice(index, 1);
    setInvoices([...newInvoices]);
  };

  const AddInvoicenput = () => {
    setInvoices([...Invoices, { tenants_id: props.tenants_id }]);
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0 };
  };

  const SubmitInvoices = (Invoice) => {
    setSaving(true);
    fetch(`${app}/invoice/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Invoice,
        linker: Date.now(),
        houses_id: GetTenant(Invoice.tenants_id).houses_id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingInvoice(details);
          setInvoices([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveInvoices = () => {
    Invoices.forEach((Invoice, index) => {
      if (/\S/.test(Invoice)) {
        SubmitInvoices({
          ...Invoice,
        });
      }
    });
  };

  const InvoiceOutput = Invoices.map((Invoice, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveInvoice(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              {!props.from ? (
                <>
                  <label>Tenant To Invoice</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      updateInvoices(e.target.value, index, "tenants_id")
                    }
                  >
                    <option value={0}>Select Tenant</option>
                    {props.Tenants.map((tenant) => (
                      <option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}

              <label>Invoice For</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) => updateInvoices(e.target.value, index, "for")}
              >
                <option value="rent">Select Particular</option>
                <option value="deposit">Deposit</option>
                <option value="rent">Rent</option>
                <option value="water">Water Bill</option>
                <option value="electricity">Electricity Bill</option>
                <option value="expenses">Other Expenses</option>
              </select>

              <label>For Month</label>
              <input
                type="month"
                className="rounded-pill form-control"
                placeholder="Select Month"
                onChange={(e) => updateInvoices(e.target.value, index, "month")}
              />

              <label>Amount</label>
              <input
                placeholder="Enter Amount To Invoice"
                value={Invoice.amount}
                onChange={(e) =>
                  updateInvoices(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "amount"
                  )
                }
                className="rounded-pill form-control"
              />

              <label className="mt-2">Invoice Other Details</label>
              <textarea
                placeholder="Enter Invoice Details"
                value={Invoice.details}
                onChange={(e) =>
                  updateInvoices(e.target.value, index, "details")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      <div className="d-flex justify-content-around">
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => $("#fetchInvoices").modal("show")}
        >
          View Invoices
        </button>
        {Invoices.length < 1 ? (
          <button
            className="btn-sm btn-info rounded-pill"
            onClick={AddInvoicenput}
          >
            Add Invoice
          </button>
        ) : null}
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() => props.history.push("/portal/bulk-invoicing")}
        >
          Bulk Invoice
        </button>
      </div>
      {InvoiceOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : Invoices.length > 0 ? (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveInvoices}
            >
              Save
            </button>
          ) : null}
        </div>
      </div>
      <div
        id="fetchInvoices"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">View Invoices</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Select Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>

                <div className="col-12 d-flex justify-content-around my-4">
                  <button
                    className="btn-xs btn-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!Loading ? (
                    <button
                      className="btn-xs btn-primary rounded-pill"
                      onClick={() => FetchInvoices()}
                    >
                      View
                    </button>
                  ) : (
                    <span className="spinner-border text-primary"></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingInvoice,
  updatingInvoice,
  deletingInvoice,
  fetchingInvoices,
})(withRouter(AddInvoices));
