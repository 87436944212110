import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loggingIn } from "../../actions/authActions";
import { fetchingClients } from "../../actions/clientActions";
import { app } from "../../constants/config";
import data from "../../Countries";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const AuthForm = (props) => {
  const [Status, setStatus] = useState("logger");
  const [Logger, setLogger] = useState("");
  const [Password, setPassword] = useState("");
  const [Confirm, setConfirm] = useState("");
  const [Phone, setPhone] = useState("");
  const [Code, setCode] = useState("");
  const [Country, setCountry] = useState("Kenya");
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.visitor.country) {
      let country = data.find(
        (country) =>
          country.code.toLowerCase() == props.visitor.country.toLowerCase()
      );
      setCountry(country.name);
    }
  }, [props.visitor]);

  const saveBanker = async (details) => {
    props.fetchingClients([]);
    await window.localStorage.clear();
    props.loggingIn(details);
    if (details.credentials.length > 0) {
      props.history.push("/portal");
    } else {
      props.history.push("/myapplication");
    }
  };

  const GetPhone = () => {
    if (Phone.replace(/\s/g, "").charAt(0) == 0) {
      return "+254" + Phone.replace(/\s/g, "").substring(1);
    } else if (Phone.replace(/\s/g, "").charAt(0) == "+") {
      return Phone.replace(/\s/g, "");
    } else {
      return Phone.replace(/\s/g, "");
    }
  };

  const FetchCredentials = (details) => {
    fetch(`${app}/auth/client`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${details.token}`,
      },

      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((credentials) => {
        setLoading(false);
        saveBanker({ credentials, ...details });
        setStatus("logger");
        setPassword("");
        setConfirm("");
        setLogger("");
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Ooops...",
          text: "Connection Error! Please try again.",
          icon: "error",
          timer: 3000,
        });
        setStatus("logger");
        setPassword("");
        setConfirm("");
        setLogger("");
      });
  };

  const checkLogger = () => {
    setLoading(true);
    fetch(`${app}/auth/checkemail`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: Logger,
        visitorId: props.visitor.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 1) {
          setStatus("login");
        } else if (details.code == 0) {
          setStatus("register");
        } else {
          swal({
            title: "Ooops...",
            text: "Server Error..Please try again",
            icon: "error",
            timer: 3000,
          });

          setStatus("logger");
          setPassword("");
          setConfirm("");
          setLogger("");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Ooops...",
          text: "Connection Error! Please try again.",
          icon: "error",
          timer: 3000,
        });
        setStatus("logger");
        setPassword("");
        setConfirm("");
        setLogger("");
      });
  };

  const forgotPassword = () => {
    setLoading(true);
    fetch(`${app}/auth/forgotPassword`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: Logger,
        countryCode: Country.dial_code,
        visitorId: props.visitor.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 1) {
          swal({
            title: `Password Reset Settings has been sent to ${details.sentTo}`,
            text: `Check Your Email To Reset`,
            icon: "success",
            confirmButtonColor: "#3085d6",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Okay!",
          }).then((result) => {});
        } else if (details.code == 0) {
          swal({
            title: `${Logger} doesn't exist in our records`,
            text: `Enter the correct email`,
            icon: "error",
            confirmButtonColor: "#3085d6",
            //cancelButtonColor: "#d33",
            confirmButtonText: "Okay!",
          }).then((result) => {});
          setStatus("logger");
          setPassword("");
          setConfirm("");
          setLogger("");
        } else {
          swal({
            title: `Ooops ...!`,
            text: "Server Error! Please try again.",
            icon: "error",
            timer: 5000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: `Ooops ...!`,
          text: "Connection Error! Please try again.",
          icon: "error",
          timer: 5000,
        });
      });
  };

  const loginBanker = () => {
    if (!/\S/.test(Password)) {
      swal({
        title: `Ooops ...!`,
        text: "Password Can't be Empty!",
        icon: "error",
        timer: 3000,
      });
      return;
    }
    setLoading(true);
    fetch(`${app}/auth/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: Logger,
        password: Password,
        countryCode: Country.dial_code,
        visitorId: props.visitor.id,
        sentFrom: "web",
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 0) {
          FetchCredentials(details);
        } else if (details.code == 1) {
          setLoading(false);
          swal({
            title: `Ooops ...!`,
            text: `Incorrect Password, Login with corect password, or Click Forgot Password to reset`,
            icon: "error",
            timer: 3000,
          });
          setStatus("login");
        } else {
          setLoading(false);
          swal({
            title: `Ooops ...!`,
            text: `Server Error!!! Please try again`,
            icon: "error",
            timer: 3000,
          });
          setStatus("login");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: `Ooops ...!`,
          text: "Connection Error!",
          icon: "error",
          timer: 3000,
        });
        setStatus("login");
      });
  };

  const RegisterBanker = () => {
    if (!/\S/.test(Phone)) {
      swal({
        title: `Ooops ...!`,
        text: "Phone Number Can't be Empty!",
        icon: "error",
        timer: 3000,
      });
      return;
    }
    if (Password !== Confirm) {
      swal({
        title: `Ooops ...!`,
        text: "Passwords must match",
        icon: "error",
        timer: 3000,
      });
      return;
    }
    if (Password.length < 6) {
      swal({
        title: `Ooops ...!`,
        text: "Weak password, minimum length is 6 characters",
        icon: "error",
        timer: 3000,
      });
      return;
    }
    setLoading(true);
    fetch(`${app}/auth/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: Logger,
        password: Password,
        name: GetPhone(),
        countryCode: data.find((country) => country.name === Country).dial_code,
        visitorId: props.visitor.id,
        sentFrom: "web",
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 0) {
          FetchCredentials(details);
        } else if (details.code == 1) {
          setLoading(false);
          swal({
            title: `Ooops ...!`,
            text: `${details.logger} is already registered`,
            icon: "error",
            timer: 3000,
          });
          setStatus("register");
        } else {
          setLoading(false);
          swal({
            title: `Ooops ...!`,
            text: `${details.name} server error has occured, please try again`,
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: `Ooops ...!`,
          text: "connection Err!!, please try again",
          icon: "error",
          timer: 3000,
        });
        setStatus("register");
      });
  };

  const submitPassword = () => {
    if (
      /\S/.test(Code) &&
      /\S/.test(Password) &&
      /\S/.test(Confirm) &&
      Password === Confirm &&
      Password.length > 6
    ) {
      setLoading(true);

      fetch(`${app}/auth/resetPassword`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          password: Password,
          token: Code,
          email: Logger,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setLoading(false);
          setPassword("");
          setConfirm("");
          if (details.code == 1) {
            swal({
              title: `Password Reset Successfully!!!`,
              text: `Continue With LogIn`,
              icon: "success",
              confirmButtonColor: "#3085d6",
              //cancelButtonColor: "#d33",
              confirmButtonText: "Okay!",
            }).then((result) => {
              setStatus("login");
            });
          } else {
            swal({
              title: `Error in resetting!!!`,
              text: `please try again`,
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setPassword("");
          setConfirm("");
          swal({
            title: `Connection Error!!!`,
            text: `Please try again`,
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: `Passwords Must Match And Min Length Of 6!!!`,
        text: `Please try again`,
        icon: "error",
        timer: 3000,
      });
    }
  };

  let countries = (
    <>
      <label>
        <strong>Select Country : </strong>
      </label>
      <select
        onChange={(e) => {
          setCountry(
            data.find((country) => country.code == e.target.value).name
          );
        }}
        value={Country}
        className="rounded w-100"
      >
        <option
          value={data.find((country) => country.name == Country)}
          key={Country}
        >
          {Country}
        </option>
        {data.map((country) => {
          return (
            <option value={country.code} key={country.code}>
              {country.name}
            </option>
          );
        })}
      </select>
    </>
  );

  return Status === "logger" ? (
    <>
      <div className="card justify-content-center rounded-lg my-auto mx-auto">
        <div className="card-header">
          <p className="text-center">
            <FontAwesomeIcon className="text-primary h1" icon={faUserCircle} />
            <br /> <span>{props.LoginType}</span>
          </p>
        </div>

        <div className="card-body">
          <p>
            <strong>Country : </strong> <em>{Country}</em>
          </p>
          {countries}
          <label className="mt-2">
            <strong>Id Number :</strong>
          </label>
          <input
            type="text"
            value={Logger}
            placeholder="Enter Id Number"
            onChange={(e) => setLogger(e.target.value)}
            className="form-control rounded text-center w-100"
          />
        </div>
        <div
          className="card-footer d-flex justify-content-center"
          style={MyStyle.footer}
        >
          {!Loading ? (
            <button
              className="btn-sm btn-primary rounded-circle"
              onClick={checkLogger}
            >
              Send
            </button>
          ) : (
            <span className="spinner-border text-primary"></span>
          )}
        </div>
      </div>
    </>
  ) : Status === "register" ? (
    <>
      <div className="card justify-content-center rounded-lg my-auto mx-auto">
        <div className="card-header">
          <p className="text-center">Sign Up {Logger}</p>
        </div>

        <div className="card-body">
          <label className="mt-2">
            <strong>Phone Number :</strong>
          </label>
          <input
            type="text"
            value={Phone}
            placeholder="Enter Phone Number"
            onChange={(e) => setPhone(e.target.value)}
            className="form-control rounded text-center w-100"
          />

          <label className="mt-2">
            <strong>Password :</strong>
          </label>
          <input
            type="password"
            value={Password}
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            className="form-control rounded text-center w-100"
          />

          <label className="mt-2">
            <strong>Confirm Password :</strong>
          </label>
          <input
            type="password"
            value={Confirm}
            placeholder="Enter Confirm Password"
            onChange={(e) => setConfirm(e.target.value)}
            className="form-control rounded text-center w-100"
          />
        </div>
        <div
          className="card-footer d-flex justify-content-around"
          style={MyStyle.footer}
        >
          {!Loading ? (
            <>
              <button
                className="btn-sm btn-danger rounded-circle"
                onClick={() => {
                  setStatus("logger");
                  setPassword("");
                  setConfirm("");
                  setLogger("");
                }}
              >
                Cancel
              </button>
              <button
                className="btn-sm btn-primary rounded-circle"
                onClick={RegisterBanker}
              >
                Send
              </button>
            </>
          ) : (
            <span className="spinner-border text-primary"></span>
          )}
        </div>
      </div>
    </>
  ) : Status === "login" ? (
    <>
      <div className="card justify-content-center rounded-lg my-auto mx-auto">
        <div className="card-header">
          <p className="text-center">Sign In {Logger}</p>
        </div>

        <div className="card-body">
          <label className="mt-2">
            <strong>Password :</strong>
          </label>
          <input
            type="password"
            value={Password}
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            className="form-control rounded text-center w-100"
          />
        </div>
        <div className="card-footer justify-content-center">
          {!Loading ? (
            <>
              <div className="d-flex justify-content-around">
                <button
                  className="btn-sm btn-danger rounded-circle"
                  onClick={() => {
                    setStatus("logger");
                    setPassword("");
                    setConfirm("");
                    setLogger("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-sm btn-primary rounded-circle"
                  onClick={loginBanker}
                >
                  Send
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <span
                  className="btn-link"
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setStatus("reset")}
                >
                  <em> Forgot Password</em>
                </span>
              </div>
            </>
          ) : (
            <p className="text-center">
              <span className="spinner-border text-primary"></span>
            </p>
          )}
        </div>
      </div>
    </>
  ) : Status == "reset" ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitPassword();
      }}
    >
      <div className="card justify-content-center rounded-lg my-auto mx-auto">
        <div className="card-header">
          <p className="text-center">Reset {Logger}</p>
        </div>

        <div className="card-body">
          <label className="mt-2">
            <strong>Reset Code :</strong>
          </label>
          <input
            type="text"
            value={Code}
            required
            placeholder="Enter Reset Code"
            onChange={(e) => setCode(e.target.value)}
            className="form-control rounded text-center w-100"
          />

          <label className="mt-2">
            <strong>Password :</strong>
          </label>
          <input
            type="password"
            value={Password}
            required
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            className="form-control rounded text-center w-100"
          />

          <label className="mt-2">
            <strong>Confirm Password :</strong>
          </label>
          <input
            type="password"
            value={Confirm}
            required
            placeholder="Enter Confirm Password"
            onChange={(e) => setConfirm(e.target.value)}
            className="form-control rounded text-center w-100"
          />
        </div>
        <div className="card-footer">
          {!Loading ? (
            <>
              <div className="d-flex justify-content-around">
                <button
                  className="btn-sm btn-danger rounded-circle"
                  onClick={() => {
                    setStatus("logger");
                    setPassword("");
                    setConfirm("");
                    setLogger("");
                    setCode("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-sm btn-primary rounded-circle"
                  type="submit"
                >
                  Reset
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <span
                  className="btn-link"
                  style={{
                    fontSize: "12px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => forgotPassword()}
                >
                  <em>Request Reset Code</em>
                </span>
              </div>
            </>
          ) : (
            <p className="text-center">
              <span className="spinner-border text-primary"></span>
            </p>
          )}
        </div>
      </div>
    </form>
  ) : (
    <div
      className="spinner-border spinner-border-sm text-center text-primary"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const MyStyle = {
  footer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
};

const mapStateToProps = (state) => ({
  visitor: state.visitor.visitor,
});

export default connect(mapStateToProps, { loggingIn, fetchingClients })(
  withRouter(AuthForm)
);
