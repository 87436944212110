import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingBuilding,
  updatingBuilding,
  deletingBuilding,
} from "../../actions/buildingActions";
import swal from "sweetalert";
import { app } from "../../constants/config";

const AddBuildings = (props) => {
  const [Buildings, setBuildings] = useState([]);
  const [Saving, setSaving] = useState(false);

  const updateBuildings = (value, index, field) => {
    Buildings[index] = { ...Buildings[index], [field]: value };
    setBuildings([...Buildings]);
  };

  const RemoveBuilding = (index) => {
    let newBuildings = [...Buildings];
    newBuildings.splice(index, 1);
    setBuildings([...newBuildings]);
  };

  const AddBuildingnput = () => {
    setBuildings([
      ...Buildings,
      { name: "", description: "", linker: Date.now(), landlords_id: 0 },
    ]);
  };

  const SubmitBuildings = (Building) => {
    setSaving(true);
    fetch(`${app}/building/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Building,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingBuilding(details);
          setBuildings([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveBuildings = () => {
    Buildings.forEach((Building, index) => {
      if (/\S/.test(Building)) {
        SubmitBuildings({
          ...Building,
        });
      }
    });
  };

  const BuildingOutput = Buildings.map((Building, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveBuilding(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Building Landlord</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) =>
                  updateBuildings(e.target.value, index, "landlords_id")
                }
              >
                <option value={0}>Select Building Landlord</option>
                {props.Landlords.map((landlord) => (
                  <option key={landlord.id} value={landlord.id}>
                    {landlord.name}
                  </option>
                ))}
              </select>

              <label>Name</label>
              <input
                placeholder="Enter Building Name"
                value={Building.name}
                onChange={(e) => updateBuildings(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />

              <label className="mt-2">Building Description</label>
              <textarea
                placeholder="Enter Building Description eg location"
                value={Building.description}
                onChange={(e) =>
                  updateBuildings(e.target.value, index, "description")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      {BuildingOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Buildings.length < 1 ? (
            <button
              className="btn-sm btn-info rounded-pill"
              onClick={AddBuildingnput}
            >
              Add Building
            </button>
          ) : Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveBuildings}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Landlords: state.Landlord.Landlords,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingBuilding,
  updatingBuilding,
  deletingBuilding,
})(AddBuildings);
