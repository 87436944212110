export const login = "login";
export const logout = "logout";
export const VIEWED_FROM = "VIEWED_FROM";
export const ADD_CREDENTIAL = "ADD_CREDENTIAL";
export const ACTIVATE_CREDENTIAL = "ACTIVATE_CREDENTIAL";
export const INSTITUTION_STRUCTURE_STATUS = "INSTITUTION_STRUCTURE_STATUS";
export const UPDATE_CREDENTIALS = "UPDATE_CREDENTIALS";
export const FETCH_STAFFS = "FETCH_STAFFS";
export const ADD_STAFF = "ADD_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const REPLACE_STAFF = "REPLACE_STAFF";
export const FETCH_LANDLORDS = "FETCH_LANDLORDS";
export const ADD_LANDLORD = "ADD_LANDLORD";
export const UPDATE_LANDLORD = "UPDATE_LANDLORD";
export const DELETE_LANDLORD = "DELETE_LANDLORD";
export const FETCH_BUILDINGS = "FETCH_BUILDINGS";
export const ADD_BUILDING = "ADD_BUILDING";
export const UPDATE_BUILDING = "UPDATE_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const ADD_MODE = "ADD_MODE";
export const UPDATE_MODE = "UPDATE_MODE";
export const DELETE_MODE = "DELETE_MODE";
export const FETCH_MODES = "FETCH_MODES";
export const FETCH_HOUSES = "FETCH_HOUSES";
export const ADD_HOUSE = "ADD_HOUSE";
export const UPDATE_HOUSE = "UPDATE_HOUSE";
export const DELETE_HOUSE = "DELETE_HOUSE";
export const FETCH_TENANTS = "FETCH_TENANTS";
export const ADD_TENANT = "ADD_TENANT";
export const UPDATE_TENANT = "UPDATE_TENANT";
export const DELETE_TENANT = "DELETE_TENANT";
export const FETCH_EXPENSES = "FETCH_EXPENSES";
export const ADD_EXPENSE = "ADD_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const ADD_INVOICE = "ADD_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const FETCH_REPAYMENTS = "FETCH_REPAYMENTS";
export const ADD_REPAYMENT = "ADD_REPAYMENT";
export const UPDATE_REPAYMENT = "UPDATE_REPAYMENT";
export const DELETE_REPAYMENT = "DELETE_REPAYMENT";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const REPLACE_CLIENT = "REPLACE_CLIENT";
export const ADD_VISITOR = "ADD_VISITOR";
export const ADD_STRUCTURE_BLOCK = "ADD_STRUCTURE_BLOCK";
export const UPDATE_STRUCTURE_BLOCK = "UPDATE_STRUCTURE_BLOCK";
export const REMOVE_STRUCTURE_BLOCK = "REMOVE_STRUCTURE_BLOCK";
export const FETCH_BRANCHES = "FETCH_BRANCHES";
export const ADD_BRANCH = "ADD_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const ADD_GROUP = "ADD_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const FETCH_ACCOUNTS = "FETCH_SACCOUNT";
export const ADD_ACCOUNT = "ADD_STAFF";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
