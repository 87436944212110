import {
  ADD_EXPENSE,
  FETCH_EXPENSES,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
} from "../actions/types";

const initialState = {
  Expenses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPENSES:
      return { ...state, Expenses: action.payload };

    case ADD_EXPENSE:
      return { ...state, Expenses: [action.payload, ...state.Expenses] };

    case UPDATE_EXPENSE:
      var newExpenses = [...state.Expenses];
      var oldBranch = newExpenses.find(
        (expense) => expense.linker == action.payload.linker
      );
      var Index = newExpenses.indexOf(oldBranch);
      newExpenses.splice(Index, 1, action.payload);
      return {
        ...state,
        Expenses: [...newExpenses],
      };
    case DELETE_EXPENSE:
      var newExpenses = [...state.Expenses];

      return {
        ...state,
        Expenses: [
          ...newExpenses.filter((expense) => expense.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
