import {
  ADD_HOUSE,
  FETCH_HOUSES,
  UPDATE_HOUSE,
  DELETE_HOUSE,
} from "../actions/types";

const initialState = {
  Houses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOUSES:
      return { ...state, Houses: action.payload };

    case ADD_HOUSE:
      return { ...state, Houses: [action.payload, ...state.Houses] };

    case UPDATE_HOUSE:
      var newHouses = [...state.Houses];
      var oldBranch = newHouses.find(
        (house) => house.linker == action.payload.linker
      );
      var Index = newHouses.indexOf(oldBranch);
      newHouses.splice(Index, 1, action.payload);
      return {
        ...state,
        Houses: [...newHouses],
      };
    case DELETE_HOUSE:
      var newHouses = [...state.Houses];

      return {
        ...state,
        Houses: [
          ...newHouses.filter((house) => house.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
