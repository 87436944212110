import React from "react";
import { connect } from "react-redux";
import DisplayRepayments from "./DisplayRepayments";
import NoRepayments from "./NoRepayments";
import AddRepayments from "./AddRepayments";

const Repayments = (props) => {
  const Status =
    props.Repayments.length > 0 ? <DisplayRepayments /> : <NoRepayments />;

  return (
    <>
      <AddRepayments></AddRepayments>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Repayments: state.Repayment.Repayments,
});

export default connect(mapStateToProps, {})(Repayments);
