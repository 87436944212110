import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingTenant, updatingTenant } from "../../actions/tenantActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayTenants = (props) => {
  const [ActiveTenant, setActiveTenant] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const saveEdittedTenant = () => {
    if (/\S/.test(ActiveTenant.name)) {
      setEditting(true);
      fetch(`${app}/tenant/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveTenant,
          TenantId: ActiveTenant.id,
          landlords_id: props.banker.user.original.landlord
            ? props.banker.user.original.landlord.id
            : props.banker.credentials.length > 0
            ? props.banker.credentials[0].landlords_id
            : 0,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editTenant").modal("hide");
          if (details.code != 2) {
            props.updatingTenant(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const saveDeletedTenant = () => {
    setDeleting(true);
    fetch(`${app}/tenant/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveTenant,
        TenantId: ActiveTenant.id,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteTenant").modal("hide");
        if ((details.code = 2)) {
          props.deletingTenant(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const saveExitedTenant = () => {
    setDeleting(true);
    fetch(`${app}/tenant/exit`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveTenant,
        TenantId: ActiveTenant.id,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);

        if ((details.code = 2)) {
          props.updatingTenant(details);
          $("#exitTenant").modal("hide");
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const FilterTenants = props.banker.credentials
    ? props.banker.credentials[0].building == "all"
      ? props.Tenants
      : props.Tenants.filter((Tenant) => {
          return StaffBuildings(props.banker.credentials[0].building).includes(
            parseInt(GetHouse(Tenant.houses_id).buildings_id)
          );
        })
    : props.Tenants;

  const TenantsDisplay = FilterTenants.map((Tenant) => (
    <tr>
      <td>{Tenant.name}</td>
      <td>
        {" "}
        <span
          className={`${Tenant.status == 1 ? "text-success" : "text-danger"}`}
        >
          <em className="ml-3"> {Tenant.status == 1 ? "Current" : "Exited"}</em>
        </span>
      </td>
      <td>{Tenant.idno}</td>
      <td>{Tenant.contact}</td>
      <td>{GetHouse(Tenant.houses_id).name}</td>
      <td>{Tenant.created_at.split(" ")[0]}</td>
      <td>{Tenant.status == 1 ? null : Tenant.updated_at.split(" ")[0]}</td>
      <td>
        {" "}
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/tenant/invoices/${Tenant.id}`)
          }
        >
          Invoices
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/tenant/payments/${Tenant.id}`)
          }
        >
          Payments
        </button>
      </td>
      <td>
        {" "}
        {Tenant.status == 1 ? (
          <button
            className="btn-sm btn-danger rounded-pill"
            onClick={() => setActiveTenant({ ...Tenant })}
            data-toggle="modal"
            data-target="#exitTenant"
          >
            Exit
          </button>
        ) : null}
      </td>
      <td>
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveTenant({ ...Tenant })}
          data-toggle="modal"
          data-target="#editTenant"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveTenant({ ...Tenant })}
          data-toggle="modal"
          data-target="#deleteTenant"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-bordered table-sm table-responsive">
          <thead>
            <tr>
              <th scope="column">Name</th>
              <th scope="column">Status</th>
              <th scope="column">IDNo</th>
              <th scope="column">Contact</th>
              <th scope="column">House</th>
              <th scope="column">Reg Date</th>
              <th scope="column">Exit Date</th>
              <th scope="column">Invoices</th>
              <th scope="column">Payments</th>
              <th scope="column">Action</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {TenantsDisplay}
        </table>
      </div>
      <div
        id="editTenant"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveTenant.name} Tenant
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <div>
                    <label>Tenant Name :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Tenant Name"
                      value={ActiveTenant.name}
                      onChange={(e) =>
                        setActiveTenant({
                          ...ActiveTenant,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Tenant IdNo:</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Tenant idno"
                      value={ActiveTenant.rent}
                      onChange={(e) =>
                        setActiveTenant({
                          ...ActiveTenant,
                          rent: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Tenant Contact:</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Tenant Contact"
                      value={ActiveTenant.contact}
                      onChange={(e) =>
                        setActiveTenant({
                          ...ActiveTenant,
                          contact: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Tenant House :</label>
                    <select
                      className="rounded-pill form-control"
                      onChange={(e) =>
                        setActiveTenant({
                          ...ActiveTenant,
                          houses_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select Tenant House</option>
                      {props.Houses.map((house) => (
                        <option key={house.id} value={house.id}>
                          {house.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Tenant Details :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Tenant Details"
                      value={ActiveTenant.details}
                      onChange={(e) =>
                        setActiveTenant({
                          ...ActiveTenant,
                          details: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedTenant()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteTenant"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveTenant.name} Tenant
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedTenant()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="exitTenant"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Exit {ActiveTenant.name} Tenant
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveExitedTenant()}
                  >
                    Exit
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  Houses: state.House.Houses,
});

export default connect(mapStateToProps, {
  deletingTenant,
  updatingTenant,
})(withRouter(DisplayTenants));
