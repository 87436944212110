import {
  ADD_ACCOUNT,
  FETCH_ACCOUNTS,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
} from "../actions/types";

const initialState = {
  accounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      return { ...state, accounts: action.payload };

    case ADD_ACCOUNT:
      return { ...state, accounts: [action.payload, ...state.accounts] };

    case UPDATE_ACCOUNT:
      var newAccounts = [...state.accounts];
      var oldAccount = newAccounts.find(
        (account) => account.linker == action.payload.linker
      );
      var Index = newAccounts.indexOf(oldAccount);
      newAccounts.splice(Index, 1, action.payload);
      return {
        ...state,
        accounts: [...newAccounts],
      };

    case DELETE_ACCOUNT:
      var newAccounts = [...state.accounts];
      return {
        ...state,
        accounts: [
          ...newAccounts.filter((account) => account.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
