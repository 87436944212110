import React from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNavItem = (props) => {
  const Navigate = (url) => {
    props.history.push(`/portal/${url}`);
    props.sideBarToggle();
  };

  return (
    <li className="list-group-item bg-primary navItem p-0 m-0">
      <p
        style={{ cursor: "pointer", padding: "0px", margin: "0px" }}
        onClick={() => Navigate(props.url)}
      >
        {" "}
        <FontAwesomeIcon className="mx-2" icon={props.icon} />
        {props.name}
      </p>
    </li>
  );
};

export default withRouter(SideNavItem);
