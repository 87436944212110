import React, { useState } from "react";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { withRouter } from "react-router-dom";
import Header from "../../components/HomeTemplates/Header";
import Footer from "../../components/HomeTemplates/Footer";
import logo from "../../images/zidi-capital-sacco-logo.jfif";
import homepage from "../../images/mybank-homepage.jpg";
import $ from "jquery";
import { Style } from "../../utils/Style";
import AuthForm from "../Auth/AuthForm";

const PasswordReset = (props) => {
  const [Password, setPassword] = useState("");
  const [Confirm, setConfirm] = useState("");
  const [Loading, setLoading] = useState(false);
  const [LoginType, setLoginType] = useState("none");

  const submitPassword = () => {
    if (
      /\S/.test(Password) &&
      /\S/.test(Confirm) &&
      Password === Confirm &&
      Password.length > 6
    ) {
      setLoading(true);

      fetch(`${app}/auth/resetPassword`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          password: Password,
          token: props.match.params.token,
          email: props.match.params.logger,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setLoading(false);
          setPassword("");
          setConfirm("");
          if (details.code == 1) {
            swal({
              title: `Password Reset Successfully!!!`,
              text: `Continue With LogIn`,
              icon: "success",
              confirmButtonColor: "#3085d6",
              //cancelButtonColor: "#d33",
              confirmButtonText: "Okay!",
            }).then((result) => {
              props.history.push("/");
            });
          } else {
            swal({
              title: `Error in resetting!!!`,
              text: `please try again`,
              icon: "error",
              button: "Okay",
            }).then((result) => {
              props.history.push("/");
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setPassword("");
          setConfirm("");
          swal({
            title: `Connection Error!!!`,
            text: `Please try again`,
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: `Passwords Must Match And Min Length Of 6!!!`,
        text: `Please try again`,
        icon: "error",
        timer: 3000,
      });
    }
  };

  return (
    <div style={{ backgroundImage: homepage }}>
      <Header updateLogin={setLoginType} display={"none"}></Header>

      <div className="content"></div>

      <div className="text-sm-start mt-5">
        <div style={Style}>
          <div className="row justify-content-center ">
            <div className="col-md-6">
              <div className="card">
                <div>
                  <p className="text-center text-primary">
                    <strong>Reset {props.match.params.logger}</strong>
                  </p>
                </div>
                <div className="form-group">
                  <label>Enter PassWord</label>
                  <input
                    type="password"
                    value={Password}
                    placeholder="Enter PassWord"
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control rounded-pill"
                  />
                </div>
                <div className="form-group">
                  <label>Confirm PassWord</label>
                  <input
                    type="password"
                    value={Confirm}
                    placeholder="Confirm PassWord"
                    onChange={(e) => setConfirm(e.target.value)}
                    className="form-control rounded-pill"
                  />
                </div>
                <div className="form-group text-center">
                  {!Loading ? (
                    <button
                      className="btn btn-primary btn-sm rounded-pill  mt-2"
                      type="button"
                      onClick={() => submitPassword()}
                    >
                      Reset
                    </button>
                  ) : (
                    <>
                      <span className="text-primary mt-2">Resetting...</span>
                      <span className="spinner-border text-primary  spinner-border-sm mt-2"></span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordReset);
