import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import {
  deletingBuilding,
  updatingBuilding,
} from "../../actions/buildingActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayBuildings = (props) => {
  const [ActiveBuilding, setActiveBuilding] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const saveEdittedBuilding = () => {
    if (/\S/.test(ActiveBuilding.name)) {
      setEditting(true);
      fetch(`${app}/building/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveBuilding,
          BuildingId: ActiveBuilding.id,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editBuilding").modal("hide");
          if (details.code != 2) {
            props.updatingBuilding(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const GetLandlord = (landlordId) => {
    let landlord = props.Landlords.find(
      (landlord) => landlord.id == landlordId
    );
    return landlord ? landlord : { name: "none", id: 0 };
  };

  const saveDeletedBuilding = () => {
    setDeleting(true);
    fetch(`${app}/building/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveBuilding,
        BuildingId: ActiveBuilding.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteBuilding").modal("hide");
        if ((details.code = 2)) {
          props.deletingBuilding(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const BuildingsDisplay = props.Buildings.map((Building) => (
    <tr>
      <td>{Building.name}</td>
      <td>{GetLandlord(Building.landlords_id).name}</td>
      <td>
        {" "}
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/building/houses/${Building.id}`)
          }
        >
          Houses
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveBuilding({ ...Building })}
          data-toggle="modal"
          data-target="#editBuilding"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveBuilding({ ...Building })}
          data-toggle="modal"
          data-target="#deleteBuilding"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column">Name</th>
              <th scope="column">Landlord</th>
              <th scope="column">Houses</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {BuildingsDisplay}
        </table>
      </div>
      <div
        id="editBuilding"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveBuilding.name} Building
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <div>
                    <label>Building Name :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Building Name"
                      value={ActiveBuilding.name}
                      onChange={(e) =>
                        setActiveBuilding({
                          ...ActiveBuilding,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Building Landlord :</label>
                    <select
                      className="rounded-pill form-control"
                      onChange={(e) =>
                        setActiveBuilding({
                          ...ActiveBuilding,
                          landlords_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select Building Landlord</option>
                      {props.Landlords.map((landlord) => (
                        <option key={landlord.id} value={landlord.id}>
                          {landlord.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Building Description :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Building Description"
                      value={ActiveBuilding.description}
                      onChange={(e) =>
                        setActiveBuilding({
                          ...ActiveBuilding,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedBuilding()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteBuilding"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveBuilding.name} Building
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedBuilding()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Buildings: state.Building.Buildings,
  Landlords: state.Landlord.Landlords,
});

export default connect(mapStateToProps, {
  deletingBuilding,
  updatingBuilding,
})(withRouter(DisplayBuildings));
