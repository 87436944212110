import "bootstrap/dist/css/bootstrap.css";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import "./index.css";
import "./BootstrapCustom.css";
import PasswordReset from "./Pages/Auth/PasswordReset";
import Privacy from "./Pages/Auth/Privacy";
import Institution from "./Pages/Institution/Institution";
import Auth from "./Pages/Auth/Auth";
import StaffAuth from "./Pages/Auth/StaffAuth";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={StaffAuth} />
        <Route exact path="/auth" component={Auth} />
        <Route path="/portal" component={Institution} />
        <Route path={`/reset/:logger/:token`} component={PasswordReset} />
        <Route path={`/privacy`} component={Privacy} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
