import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingTenants } from "../../actions/tenantActions";
import DisplayTenants from "../Tenants/DisplayTenants";
import NoTenants from "../Tenants/NoTenants";
import AddTenants from "../Tenants/AddTenants";

const HouseTenants = (props) => {
  useEffect(() => {
    FetchTenants();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState(false);

  const FetchTenants = () => {
    setLoading(true);
    fetch(`${app}/tenant/house`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        houseId: window.location.href.split("/").pop(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingTenants(details);
        } else {
          props.fetchingTenants([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingTenants([]);
        setLoading(false);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Tenants.length > 0 ? (
    <DisplayTenants />
  ) : (
    <NoTenants />
  );

  return (
    <>
      <p className="h6 text-center text-primary">
        <strong>
          {GetHouse(window.location.href.split("/").pop()).name} Tenants
        </strong>
      </p>
      <AddTenants></AddTenants>
      {Status}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  Houses: state.House.Houses,
});

export default connect(mapStateToProps, {
  fetchingTenants,
})(HouseTenants);
