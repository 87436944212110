import { ADD_STAFF, FETCH_STAFFS, UPDATE_STAFF, REPLACE_STAFF } from "./types";

export const fetchingStaffs = staffs => dispatch => {
  dispatch({ type: FETCH_STAFFS, payload: staffs });
};

export const addingStaff = staff => dispatch => {
  dispatch({ type: ADD_STAFF, payload: staff });
};

export const updatingStaff = newStaff => dispatch => {
  dispatch({ type: UPDATE_STAFF, payload: newStaff });
};

export const ReplacingStaff = newStaff => dispatch => {
  dispatch({ type: REPLACE_STAFF, payload: newStaff });
};
