import {
  ADD_INVOICE,
  FETCH_INVOICES,
  UPDATE_INVOICE,
  DELETE_INVOICE,
} from "./types";

export const fetchingInvoices = (invoices) => (dispatch) => {
  dispatch({ type: FETCH_INVOICES, payload: invoices });
};

export const addingInvoice = (invoice) => (dispatch) => {
  dispatch({ type: ADD_INVOICE, payload: invoice });
};

export const updatingInvoice = (invoice) => (dispatch) => {
  dispatch({ type: UPDATE_INVOICE, payload: invoice });
};

export const deletingInvoice = (invoiceId) => (dispatch) => {
  dispatch({ type: DELETE_INVOICE, payload: invoiceId });
};
