import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingExpense, updatingExpense } from "../../actions/expenseActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayExpenses = (props) => {
  const [ActiveExpense, setActiveExpense] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const saveEdittedExpense = () => {
    if (/\S/.test(ActiveExpense.name)) {
      setEditting(true);
      fetch(`${app}/expense/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveExpense,
          ExpenseId: ActiveExpense.id,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editExpense").modal("hide");
          if (details.code != 2) {
            props.updatingExpense(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const saveDeletedExpense = () => {
    setDeleting(true);
    fetch(`${app}/expense/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveExpense,
        ExpenseId: ActiveExpense.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteExpense").modal("hide");
        if ((details.code = 2)) {
          props.deletingExpense(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const ExpensesDisplay = props.Expenses.map((Expense) => (
    <tr>
      <td>{Expense.name}</td>
      <td>{Expense.type}</td>
      <td>{Expense.amount}</td>
      <td>{Expense.mode}</td>
      <td>{Expense.bankerEmail}</td>
      <td>
        {
          new Date(parseInt(Expense.linker))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")
            .split(" ")[0]
        }
      </td>
      <td>{Expense.description}</td>
      <td>
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveExpense({ ...Expense })}
          data-toggle="modal"
          data-target="#editExpense"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        {" "}
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveExpense({ ...Expense })}
          data-toggle="modal"
          data-target="#deleteExpense"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column" colSpan={5}>
                Total
              </th>
              <th scope="column" colSpan={5}>
                {props.Expenses.reduce((a, b) => +a + +b.amount, 0)}
              </th>
            </tr>
            <tr>
              <th scope="column">Name</th>
              <th scope="column">Type</th>
              <th scope="column">Amount</th>
              <th scope="column">Mode</th>
              <th scope="column">By</th>
              <th scope="column">Date</th>
              <th scope="column">Details</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {ExpensesDisplay}
        </table>
      </div>

      <div
        id="editExpense"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveExpense.name} Expense
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <div>
                    <label>Expense Name :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Expense Name"
                      value={ActiveExpense.name}
                      onChange={(e) =>
                        setActiveExpense({
                          ...ActiveExpense,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Expense Type</label>
                    <select
                      className="rounded-pill form-control"
                      onChange={(e) =>
                        setActiveExpense({
                          ...ActiveExpense,
                          type: e.target.value,
                        })
                      }
                    >
                      <option value="General">Select Expense Type</option>
                      <option value="General">General</option>
                      <option value="Rent">Rent</option>
                      <option value="Salary">Salary</option>
                      <option value="Electricity">Electricity</option>
                      <option value="Water">Water</option>
                      <option value="Water">Deposit Refund</option>
                    </select>
                  </div>
                  <div>
                    <label>Expense Description :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Expense Description eg bedsitter, one-bedroom"
                      value={ActiveExpense.description}
                      onChange={(e) =>
                        setActiveExpense({
                          ...ActiveExpense,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Payment Mode</label>
                    <select
                      className="rounded-pill form-control"
                      onChange={(e) =>
                        setActiveExpense({
                          ...ActiveExpense,
                          mode: e.target.value,
                        })
                      }
                    >
                      <option value="cash">Select Payment Mode</option>
                      {props.Modes.map((mode) => (
                        <option value={mode.name}>{mode.name}</option>
                      ))}
                      <option value="cash">Cash</option>
                      <option value="bank">Bank</option>
                      <option value="electronic">Electronic/M-pesa</option>
                    </select>
                  </div>
                  <div>
                    <label>Expense Amount:</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Expense Amount"
                      value={ActiveExpense.amount}
                      onChange={(e) =>
                        setActiveExpense({
                          ...ActiveExpense,
                          amount:
                            e.target.value.slice(-1) == "-" ||
                            e.target.value.slice(-1) == "." ||
                            !isNaN(e.target.value.slice(-1))
                              ? e.target.value
                              : e.target.value.slice(0, -1),
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedExpense()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteExpense"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveExpense.name} Expense
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedExpense()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Expenses: state.Expense.Expenses,
  Buildings: state.Building.Buildings,
  Modes: state.Mode.Modes,
});

export default connect(mapStateToProps, {
  deletingExpense,
  updatingExpense,
})(withRouter(DisplayExpenses));
