import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingHouse, updatingHouse } from "../../actions/houseActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayHouses = (props) => {
  const [ActiveHouse, setActiveHouse] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);
  const [View, setView] = useState("all");

  const saveEdittedHouse = () => {
    if (/\S/.test(ActiveHouse.name)) {
      setEditting(true);
      fetch(`${app}/house/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveHouse,
          HouseId: ActiveHouse.id,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editHouse").modal("hide");
          if (details.code != 2) {
            props.updatingHouse(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const GetBuilding = (buildingId) => {
    let building = props.Buildings.find(
      (building) => building.id == buildingId
    );
    return building ? building : { name: "none", id: 0 };
  };

  const saveDeletedHouse = () => {
    setDeleting(true);
    fetch(`${app}/house/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveHouse,
        HouseId: ActiveHouse.id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deleteHouse").modal("hide");
        if ((details.code = 2)) {
          props.deletingHouse(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const FilterHouses =
    View == "all"
      ? props.banker.credentials.length > 0
        ? props.banker.credentials[0].building == "all"
          ? props.Houses
          : props.Houses.filter((House) =>
              StaffBuildings(props.banker.credentials[0].building).includes(
                parseInt(House.buildings_id)
              )
            )
        : props.Houses
      : props.banker.credentials.length > 0
      ? props.banker.credentials[0].building == "all"
        ? props.Houses.filter(
            (House) =>
              House.tenants.map((tenant) => tenant.status).includes("1") ==
              false
          )
        : props.Houses.filter(
            (House) =>
              House.tenants.map((tenant) => tenant.status).includes("1") ==
                false &&
              StaffBuildings(
                parseInt(props.banker.credentials[0].building)
              ).includes(parseInt(House.buildings_id))
          )
      : props.Houses.filter(
          (House) =>
            House.tenants.map((tenant) => tenant.status).includes("1") == false
        );

  const HousesDisplay = FilterHouses.map((House) => (
    <tr>
      <td>{House.name}</td>
      <td>
        {" "}
        <span
          className={`${
            House.tenants
              ? House.tenants.map((tenant) => tenant.status).includes("1")
                ? "text-success"
                : "text-danger"
              : "text-danger"
          }`}
        >
          <em className="ml-3">
            {" "}
            {House.tenants
              ? House.tenants.map((tenant) => tenant.status).includes("1")
                ? "Occupied"
                : "Vacant"
              : "Vacant"}
          </em>
        </span>
      </td>
      <td>{GetBuilding(House.buildings_id).name}</td>
      <td>{House.rent}</td>
      <td>{House.deposit}</td>
      <td>{House.description}</td>
      <td>
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/house/tenants/${House.id}`)
          }
        >
          Tenant
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/house/invoices/${House.id}`)
          }
        >
          Invoices
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-info rounded-pill"
          onClick={() =>
            props.history.push(`/portal/house/payments/${House.id}`)
          }
        >
          Payments
        </button>
      </td>

      <td>
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActiveHouse({ ...House })}
          data-toggle="modal"
          data-target="#editHouse"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActiveHouse({ ...House })}
          data-toggle="modal"
          data-target="#deleteHouse"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <div className="d-flex justify-content-center">
          <select onChange={(e) => setView(e.target.value)}>
            <option value={"all"}>All Houses</option>
            <option value={"vacant"}>Vacant Houses</option>
          </select>
        </div>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column">Name</th>
              <th scope="column">Status</th>
              <th scope="column">Building</th>
              <th scope="column">Rent</th>
              <th scope="column">Deposit</th>
              <th scope="column">Details</th>
              <th scope="column">Tenant</th>
              <th scope="column">Invoices</th>
              <th scope="column">Payments</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {HousesDisplay}
        </table>
      </div>
      <div
        id="editHouse"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveHouse.name} House
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <div>
                    <label>House No/ Name :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter House Name"
                      value={ActiveHouse.name}
                      onChange={(e) =>
                        setActiveHouse({
                          ...ActiveHouse,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>House Building :</label>
                    <select
                      className="rounded-pill form-control"
                      onChange={(e) =>
                        setActiveHouse({
                          ...ActiveHouse,
                          buildings_id: e.target.value,
                        })
                      }
                    >
                      <option value={0}>Select House Building</option>
                      {props.Buildings.map((building) => (
                        <option key={building.id} value={building.id}>
                          {building.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>House Description :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter House Description eg bedsitter, one-bedroom"
                      value={ActiveHouse.description}
                      onChange={(e) =>
                        setActiveHouse({
                          ...ActiveHouse,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Rent Amount:</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter House Amount"
                      value={ActiveHouse.rent}
                      onChange={(e) =>
                        setActiveHouse({
                          ...ActiveHouse,
                          rent:
                            e.target.value.slice(-1) == "-" ||
                            e.target.value.slice(-1) == "." ||
                            !isNaN(e.target.value.slice(-1))
                              ? e.target.value
                              : e.target.value.slice(0, -1),
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Rent Deposit:</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter House Deposit"
                      value={ActiveHouse.deposit}
                      onChange={(e) =>
                        setActiveHouse({
                          ...ActiveHouse,
                          deposit:
                            e.target.value.slice(-1) == "-" ||
                            e.target.value.slice(-1) == "." ||
                            !isNaN(e.target.value.slice(-1))
                              ? e.target.value
                              : e.target.value.slice(0, -1),
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedHouse()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deleteHouse"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActiveHouse.name} House
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedHouse()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Houses: state.House.Houses,
  Buildings: state.Building.Buildings,
});

export default connect(mapStateToProps, {
  deletingHouse,
  updatingHouse,
})(withRouter(DisplayHouses));
