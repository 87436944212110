import { combineReducers } from "redux";
import authReducer from "./authReducer";
import staffReducer from "./staffReducer";
import clientReducer from "./clientReducer";
import visitorReducer from "./visitorReducer";
import accountReducer from "./accountReducer";
import groupReducer from "./groupReducer";
import branchReducer from "./branchReducer";
import viewedReducer from "./viewedReducer";
import landlordReducer from "./landlordReducer";
import buildingReducer from "./buildingReducer";
import houseReducer from "./houseReducer";
import tenantReducer from "./tenantReducer";
import expenseReducer from "./expenseReducer";
import invoiceReducer from "./invoiceReducer";
import paymentReducer from "./paymentReducer";
import repaymentReducer from "./repaymentReducer";
import modeReducer from "./modeReducer";

export default combineReducers({
  auth: authReducer,
  staff: staffReducer,
  Landlord: landlordReducer,
  Building: buildingReducer,
  Mode: modeReducer,
  House: houseReducer,
  Tenant: tenantReducer,
  Expense: expenseReducer,
  Invoice: invoiceReducer,
  Payment: paymentReducer,
  Repayment: repaymentReducer,
  client: clientReducer,
  visitor: visitorReducer,
  account: accountReducer,
  branch: branchReducer,

  group: groupReducer,
  viewed: viewedReducer,
});
