import {
  ADD_BUILDING,
  FETCH_BUILDINGS,
  UPDATE_BUILDING,
  DELETE_BUILDING,
} from "./types";

export const fetchingBuildings = (buildings) => (dispatch) => {
  dispatch({ type: FETCH_BUILDINGS, payload: buildings });
};

export const addingBuilding = (building) => (dispatch) => {
  dispatch({ type: ADD_BUILDING, payload: building });
};

export const updatingBuilding = (building) => (dispatch) => {
  dispatch({ type: UPDATE_BUILDING, payload: building });
};

export const deletingBuilding = (buildingId) => (dispatch) => {
  dispatch({ type: DELETE_BUILDING, payload: buildingId });
};
