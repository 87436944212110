import {
  ADD_PAYMENT,
  FETCH_PAYMENTS,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
} from "./types";

export const fetchingPayments = (payments) => (dispatch) => {
  dispatch({ type: FETCH_PAYMENTS, payload: payments });
};

export const addingPayment = (payment) => (dispatch) => {
  dispatch({ type: ADD_PAYMENT, payload: payment });
};

export const updatingPayment = (payment) => (dispatch) => {
  dispatch({ type: UPDATE_PAYMENT, payload: payment });
};

export const deletingPayment = (paymentId) => (dispatch) => {
  dispatch({ type: DELETE_PAYMENT, payload: paymentId });
};
