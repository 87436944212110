import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingExpense,
  updatingExpense,
  deletingExpense,
  fetchingExpenses,
} from "../../actions/expenseActions";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";

const AddExpenses = (props) => {
  const [Expenses, setExpenses] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");

  const FetchExpenses = () => {
    setLoading(true);
    fetch(`${app}/expense/month`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          Month.split("-")[0],
          Month.split("-")[1] - 1,
          1
        ).getTime(),
        toDate:
          new Date(Month.split("-")[0], Month.split("-")[1] - 1, 1).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(Month.split("-")[0], Month.split("-")[1], 0).getDate(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 2) {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        } else {
          props.fetchingExpenses(details);
          $("#fetchExpenses").modal("hide");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const updateExpenses = (value, index, field) => {
    Expenses[index] = { ...Expenses[index], [field]: value };
    setExpenses([...Expenses]);
  };

  const RemoveExpense = (index) => {
    let newExpenses = [...Expenses];
    newExpenses.splice(index, 1);
    setExpenses([...newExpenses]);
  };

  const AddExpensenput = () => {
    setExpenses([
      ...Expenses,
      {
        name: "",
        description: "",
        linker: Date.now(),
        type: "General",
        amount: 0,
        mode: "cash",
      },
    ]);
  };

  const SubmitExpenses = (Expense) => {
    setSaving(true);
    fetch(`${app}/expense/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Expense,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingExpense(details);
          setExpenses([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveExpenses = () => {
    Expenses.forEach((Expense, index) => {
      if (/\S/.test(Expense)) {
        SubmitExpenses({
          ...Expense,
        });
      }
    });
  };

  const ExpenseOutput = Expenses.map((Expense, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveExpense(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Expense Name</label>
              <input
                placeholder="Enter Expense Name"
                value={Expense.name}
                onChange={(e) => updateExpenses(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />

              <label className="mt-2">Expense Description</label>
              <textarea
                placeholder="Enter Expense Description "
                value={Expense.description}
                onChange={(e) =>
                  updateExpenses(e.target.value, index, "description")
                }
                className="rounded-pill form-control"
              />
              <label>Expense Type</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) => updateExpenses(e.target.value, index, "type")}
              >
                <option value="General">Select Expense Type</option>
                <option value="General">General</option>
                <option value="Rent">Rent</option>
                <option value="Salary">Salary</option>
                <option value="Electricity">Electricity</option>
                <option value="Water">Water</option>
                <option value="Deposit">Deposit Refund</option>
              </select>

              <label>Payment Mode</label>

              <select
                className="rounded-pill form-control"
                onChange={(e) => updateExpenses(e.target.value, index, "mode")}
              >
                <option value="cash">Select Payment Mode</option>
                {props.Modes.map((mode) => (
                  <option value={mode.name}>{mode.name}</option>
                ))}

                <option value="cash">Cash</option>
                <option value="bank">Bank</option>
                <option value="electronic">Electronic/M-pesa</option>
              </select>

              <label>Expense Amount</label>
              <input
                placeholder="Enter Expense Amount"
                value={Expense.amount}
                onChange={(e) =>
                  updateExpenses(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "amount"
                  )
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div>
      <div className="d-flex justify-content-around">
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => $("#fetchExpenses").modal("show")}
        >
          View Expenses
        </button>
        {Expenses.length < 1 ? (
          <button
            className="btn-sm btn-info rounded-pill"
            onClick={AddExpensenput}
          >
            Add Expense
          </button>
        ) : null}
      </div>
      {ExpenseOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : Expenses.length > 0 ? (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveExpenses}
            >
              Save
            </button>
          ) : null}
        </div>
      </div>
      <div
        id="fetchExpenses"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">View Expenses</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Select Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>

                <div className="col-12 d-flex justify-content-around my-4">
                  <button
                    className="btn-xs btn-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!Loading ? (
                    <button
                      className="btn-xs btn-primary rounded-pill"
                      onClick={() => FetchExpenses()}
                    >
                      View
                    </button>
                  ) : (
                    <span className="spinner-border text-primary"></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Buildings: state.Building.Buildings,
  activeAuthorization: state.auth.activeAuthorization,
  Modes: state.Mode.Modes,
});

export default connect(mapStateToProps, {
  addingExpense,
  updatingExpense,
  deletingExpense,
  fetchingExpenses,
})(AddExpenses);
