import { ADD_MODE, FETCH_MODES, UPDATE_MODE, DELETE_MODE } from "./types";

export const fetchingModes = (modess) => (dispatch) => {
  dispatch({ type: FETCH_MODES, payload: modess });
};

export const addingMode = (modes) => (dispatch) => {
  dispatch({ type: ADD_MODE, payload: modes });
};

export const updatingMode = (modes) => (dispatch) => {
  dispatch({ type: UPDATE_MODE, payload: modes });
};

export const deletingMode = (modesId) => (dispatch) => {
  dispatch({ type: DELETE_MODE, payload: modesId });
};
