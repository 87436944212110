import {
  ADD_CLIENT,
  FETCH_CLIENTS,
  UPDATE_CLIENT,
  REPLACE_CLIENT,
} from "../actions/types";

const initialState = {
  clients: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS:
      return { ...state, clients: action.payload };
    case ADD_CLIENT:
      return { ...state, clients: [action.payload, ...state.clients] };
    case REPLACE_CLIENT:
      var newClients = [...state.clients];
      var oldClient = newClients.find(
        (client) => client.linker == action.payload.linker
      );
      var Index = newClients.indexOf(oldClient);
      newClients.splice(Index, 1, action.payload);
      return {
        ...state,
        clients: [...newClients],
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: [
          action.payload,
          ...state.clients.filter((client) => client.id != action.payload.id),
        ],
      };
    default:
      return state;
  }
};
