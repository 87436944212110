import {
  ADD_LANDLORD,
  FETCH_LANDLORDS,
  UPDATE_LANDLORD,
  DELETE_LANDLORD,
} from "../actions/types";

const initialState = {
  Landlords: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LANDLORDS:
      return { ...state, Landlords: action.payload };

    case ADD_LANDLORD:
      return { ...state, Landlords: [action.payload, ...state.Landlords] };

    case UPDATE_LANDLORD:
      var newLandlords = [...state.Landlords];
      var oldBranch = newLandlords.find(
        (landlord) => landlord.linker == action.payload.linker
      );
      var Index = newLandlords.indexOf(oldBranch);
      newLandlords.splice(Index, 1, action.payload);
      return {
        ...state,
        Landlords: [...newLandlords],
      };
    case DELETE_LANDLORD:
      var newLandlords = [...state.Landlords];

      return {
        ...state,
        Landlords: [
          ...newLandlords.filter(
            (landlord) => landlord.linker != action.payload
          ),
        ],
      };

    default:
      return state;
  }
};
