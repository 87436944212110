import React from "react";

const NoExpenses = () => {
  return (
    <div className="jumbotron justify-content-center">
      <h3 className="h3 text-center text-primary">No Expenses</h3>
    </div>
  );
};

export default NoExpenses;
