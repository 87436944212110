import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingBuildings } from "../../actions/buildingActions";
import DisplayBuildings from "../Buildings/DisplayBuildings";
import NoBuildings from "../Buildings/NoBuildings";
import AddBuildings from "../Buildings/AddBuildings";

const LandlordBuildings = (props) => {
  useEffect(() => {
    FetchBuildings();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState(false);

  const FetchBuildings = () => {
    setLoading(true);
    fetch(`${app}/building/landlord`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        landlordId: window.location.href.split("/").pop(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingBuildings(details);
        } else {
          props.fetchingBuildings([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingBuildings([]);
        setLoading(false);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetLandlord = (LandlordId) => {
    let landlord = props.Landlords.find(
      (landlord) => (landlord.id = LandlordId)
    );
    return landlord ? landlord : { name: "Unknown" };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Buildings.length > 0 ? (
    <DisplayBuildings />
  ) : (
    <NoBuildings />
  );

  return (
    <div>
      <p className="h6 text-center text-primary">
        <strong>
          {GetLandlord(window.location.href.split("/").pop()).name} Buildings
        </strong>
      </p>
      <AddBuildings></AddBuildings>
      {Status}
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Buildings: state.Building.Buildings,
  Landlords: state.Landlord.Landlords,
});

export default connect(mapStateToProps, {
  fetchingBuildings,
})(LandlordBuildings);
