import React from "react";

const NoTenants = () => {
  return (
    <div className="jumbotron justify-content-center">
      <h3 className="h3 text-center text-primary"> No Tenants</h3>
    </div>
  );
};

export default NoTenants;
