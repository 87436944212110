import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingInvoices } from "../../actions/invoiceActions";
import DisplayInvoices from "../Invoices/DisplayInvoices";
import NoInvoices from "../Invoices/NoInvoices";
import AddInvoices from "../Invoices/AddInvoices";

const HouseInvoices = (props) => {
  useEffect(() => {
    FetchInvoices();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState(false);

  const FetchInvoices = () => {
    setLoading(true);
    fetch(`${app}/invoice/house`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        houseId: window.location.href.split("/").pop(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingInvoices(details);
        } else {
          props.fetchingInvoices([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingInvoices([]);
        setLoading(false);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Invoices.length > 0 ? (
    <DisplayInvoices />
  ) : (
    <NoInvoices />
  );

  return (
    <>
      <p className="h6 text-center text-primary">
        <strong>
          {GetHouse(window.location.href.split("/").pop()).name} Invoices
        </strong>
      </p>
      {GetHouse(window.location.href.split("/").pop()).tenants ? (
        GetHouse(window.location.href.split("/").pop())
          .tenants.map((tenant) => tenant.status)
          .includes("1") ? (
          <AddInvoices
            from={"houses"}
            tenants_id={
              GetHouse(window.location.href.split("/").pop()).tenants.find(
                (tenant) => tenant.status == 1
              ).id
            }
          ></AddInvoices>
        ) : null
      ) : null}
      {Status}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Invoices: state.Invoice.Invoices,
  Houses: state.House.Houses,
});

export default connect(mapStateToProps, {
  fetchingInvoices,
})(HouseInvoices);
