import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingHouse,
  updatingHouse,
  deletingHouse,
} from "../../actions/houseActions";
import swal from "sweetalert";
import { app } from "../../constants/config";

const AddHouses = (props) => {
  const [Houses, setHouses] = useState([]);
  const [Saving, setSaving] = useState(false);

  const updateHouses = (value, index, field) => {
    Houses[index] = { ...Houses[index], [field]: value };
    setHouses([...Houses]);
  };

  const RemoveHouse = (index) => {
    let newHouses = [...Houses];
    newHouses.splice(index, 1);
    setHouses([...newHouses]);
  };

  const AddHousenput = () => {
    setHouses([
      ...Houses,
      {
        name: "",
        description: "",
        linker: Date.now(),
        buildings_id: 0,
        rent: 0,
        deposit: 0,
      },
    ]);
  };

  const SubmitHouses = (House) => {
    setSaving(true);
    fetch(`${app}/house/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...House,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingHouse(details);
          setHouses([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveHouses = () => {
    Houses.forEach((House, index) => {
      if (/\S/.test(House)) {
        SubmitHouses({
          ...House,
        });
      }
    });
  };

  const HouseOutput = Houses.map((House, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveHouse(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>House Building</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) =>
                  updateHouses(e.target.value, index, "buildings_id")
                }
              >
                <option value={0}>Select House Building</option>
                {props.Buildings.map((building) => (
                  <option key={building.id} value={building.id}>
                    {building.name}
                  </option>
                ))}
              </select>

              <label>House No/Name</label>
              <input
                placeholder="Enter House Name"
                value={House.name}
                onChange={(e) => updateHouses(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />

              <label className="mt-2">House Description</label>
              <textarea
                placeholder="Enter House Description eg bedsitter, one-bedroom"
                value={House.description}
                onChange={(e) =>
                  updateHouses(e.target.value, index, "description")
                }
                className="rounded-pill form-control"
              />

              <label>Rent Amount</label>
              <input
                placeholder="Enter House Rent"
                value={House.rent}
                onChange={(e) =>
                  updateHouses(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "rent"
                  )
                }
                className="rounded-pill form-control"
              />

              <label>Deposit Amount</label>
              <input
                placeholder="Enter House Deposit"
                value={House.deposit}
                onChange={(e) =>
                  updateHouses(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "deposit"
                  )
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      {HouseOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Houses.length < 1 ? (
            <button
              className="btn-sm btn-info rounded-pill"
              onClick={AddHousenput}
            >
              Add House
            </button>
          ) : Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveHouses}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Buildings: state.Building.Buildings,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingHouse,
  updatingHouse,
  deletingHouse,
})(AddHouses);
