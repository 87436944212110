import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  updatingStaff,
  fetchingStaffs,
  ReplacingStaff,
} from "../../../actions/staffActions";
import { app } from "../../../constants/config";
import Countries from "../../../Countries";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const DisplayStaffs = (props) => {
  const [ActiveStaff, setActiveStaff] = useState({ building: [] });
  const [SearchStaff, setSearchStaff] = useState("");
  const [NewStatus, setNewStatus] = useState("");
  const [Authorization, setAuthorization] = useState("");
  const [Editting, setEditting] = useState(false);
  const [Deleting, setDeleting] = useState(false);
  const [Country, setCountry] = useState({
    name: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dial_code: "+254",
  });

  useEffect(() => {
    if (props.visitor.country) {
      let country = Countries.find(
        (country) =>
          country.code.toLowerCase() == props.visitor.country.toLowerCase()
      );
      setCountry(country);
    }
  }, [props.visitor]);

  const editStaff = (staffId) => {
    let staff = props.staffs.find((staff) => staff.id === staffId);
    if (staff) {
      setActiveStaff({
        ...staff,
        building: StaffBuildings(staff.building),
        status: "editStaff",
      });
    } else {
      swal({
        title: "Error!",
        span: "Please try again",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const deleteStaff = (staffId) => {
    let staff = props.staffs.find((staff) => staff.id === staffId);
    if (staff) {
      setActiveStaff({
        ...staff,
        building: StaffBuildings(staff.building),
        status: "deleteStaff",
      });
    } else {
      swal({
        title: "Error!",
        span: "Please try again",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const saveEdittedStaff = () => {
    if (/\S/.test(ActiveStaff.firstname)) {
      let contact;
      if (/\S/.test(ActiveStaff.contact)) {
        if (ActiveStaff.contact.charAt(0) !== "+") {
          contact = Country.dial_code + ActiveStaff.contact.substr(1);
        } else {
          contact = ActiveStaff.contact;
        }
      }

      setEditting(true);

      fetch(`${app}/staff/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActiveStaff,
          contact,
          email: ActiveStaff.email.toLowerCase(),
          building: JSON.stringify(ActiveStaff.building),
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          if (details.code != 2) {
            $("#editStaff").modal("hide");
            props.ReplacingStaff(details);
          } else {
            swal({
              title: "Server Error!",
              span: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Network Error!",
            span: "Please try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        span: "Fill the name",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const saveDeletedStaff = () => {
    setDeleting(true);
    fetch(`${app}/staff/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveStaff,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        if (details.code != 2) {
          $("#removeStaff").modal("hide");
          let staffs = [...props.staffs];
          let oldStaff = staffs.find((staff) => staff.id == details.id);
          let Index = staffs.indexOf(oldStaff);
          staffs.splice(Index, 1);
          props.fetchingStaffs(staffs);
        } else {
          swal({
            title: "Server Error!",
            span: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);
        swal({
          title: "Network Error!",
          span: "Please try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const updateAuthorizations = () => {
    setEditting(true);
    fetch(`${app}/staff/edit`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActiveStaff,
        [Authorization.toLowerCase()]: NewStatus,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setEditting(false);
        if (details.code != 0) {
          props.ReplacingStaff(details);
          $("#staffStatus").modal("hide");
        } else {
          swal({
            title: "Server Error!",
            span: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setEditting(false);
        swal({
          title: "Network Error!",
          span: "Please try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetBuilding = (buildingId) => {
    let building = props.Buildings.find(
      (building) => building.id == buildingId
    );
    return building ? building : { name: "none", id: 0 };
  };

  const viewStaffAuthorization = (
    status,
    authorization,
    staff,
    savedStatus
  ) => {
    let output =
      status == 1 ? (
        <div className="d-flex justify-content-center my-2">
          {savedStatus == 1 ? (
            <span
              className="btn-link text-success rounded-circle mx-3"
              onClick={() => {
                setActiveStaff({ ...staff });
                setNewStatus(0);
                setAuthorization(authorization);
                $("#staffStatus").modal("show");
              }}
              style={{ cursor: "pointer" }}
            >
              {authorization}
            </span>
          ) : null}
        </div>
      ) : (
        <div className="d-flex justify-content-center my-2">
          {savedStatus == 1 ? (
            <span
              className="btn-link text-primary rounded-circle mx-3"
              onClick={() => {
                setActiveStaff({ ...staff });
                setNewStatus(1);
                setAuthorization(authorization);
                $("#staffStatus").modal("show");
              }}
              style={{ cursor: "pointer" }}
            >
              {authorization}
            </span>
          ) : null}
        </div>
      );
    return output;
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const StaffsDisplay = props.staffs.map((staff) => (
    <>
      <tr>
        <td>{staff.firstname}</td>
        <td> {staff.lastname}</td>
        <td> {staff.surname}</td>
        <td>{staff.idNo}</td>
        <td>{staff.email}</td>
        <td>{staff.contact}</td>
        <td>
          <div class="dropdown">
            <span
              class="dropdown-toggle text-dark text-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Buildings
            </span>
            <div
              class="dropdown-menu"
              style={{ zIndex: "6000" }}
              aria-labelledby="dropdownMenuButton"
            >
              {staff.building == "all" ? (
                <p className="my-0 py-0">All Buildings</p>
              ) : (
                StaffBuildings(staff.building).map((building) => (
                  <p className="my-0 py-0">{GetBuilding(building).name}</p>
                ))
              )}
            </div>
          </div>
        </td>
        <td>
          {" "}
          <div class="dropdown">
            <span
              class="dropdown-toggle text-dark text-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Authorizations
            </span>
            <div
              class="dropdown-menu"
              style={{ zIndex: "6000" }}
              aria-labelledby="dropdownMenuButton"
            >
              <span>
                {viewStaffAuthorization(
                  staff.admin,
                  "Admin",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {" "}
                {viewStaffAuthorization(
                  staff.expenses,
                  "Expenses",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.payments,
                  "Payments",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.tenants,
                  "Tenants",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.landlords,
                  "Landlords",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.houses,
                  "Houses",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.buildings,
                  "Buildings",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.reports,
                  "Reports",
                  staff,
                  staff.status
                )}
              </span>
              <span>
                {viewStaffAuthorization(
                  staff.invoices,
                  "Invoices",
                  staff,
                  staff.status
                )}
              </span>
            </div>
          </div>
        </td>
        <td>
          {" "}
          <button
            className="btn btn-sm btn-info rounded-circle mx-3"
            data-toggle="modal"
            data-target="#editStaff"
            onClick={() => editStaff(staff.id)}
          >
            <FontAwesomeIcon className="mx-2" icon={faEdit} />
          </button>
        </td>
        <td>
          {" "}
          <button
            className="btn btn-sm btn-danger rounded-circle mx-3"
            data-toggle="modal"
            data-target="#removeStaff"
            onClick={() => deleteStaff(staff.id)}
          >
            <FontAwesomeIcon className="mx-2" icon={faTrash} />
          </button>
        </td>
      </tr>
    </>
  ));

  return (
    <div>
      <div
        id="editStaff"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActiveStaff.firstname} {ActiveStaff.lastname}{" "}
                {ActiveStaff.surname}
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <div className="card card-primary">
                  <div>
                    <label>Id No :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Id No"
                      value={ActiveStaff.idNo}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          idNo: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <label>firstname :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter firstname"
                      value={ActiveStaff.firstname}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          firstname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <label>lastname :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter lastname"
                      value={ActiveStaff.lastname}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          lastname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <label>surname :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter surname"
                      value={ActiveStaff.surname}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          surname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <label>Email :</label>
                    <input
                      type="email"
                      className="rounded-pill form-control"
                      placeholder="Enter Email"
                      value={ActiveStaff.email}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <label>Contact :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Contact"
                      value={ActiveStaff.contact}
                      onChange={(e) =>
                        setActiveStaff({
                          ...ActiveStaff,
                          contact: e.target.value,
                        })
                      }
                    />
                  </div>
                  <hr />
                  <div>
                    <p className="h6 text-primary text-center">
                      Select Building{" "}
                    </p>
                    <div className="row justify-content-center">
                      {props.Buildings.map((building) => (
                        <div className="col-12 col-md-4" key={building.id}>
                          <span style={{ marginRight: 5 }}>
                            {building.name}
                          </span>
                          <input
                            type="checkbox"
                            onChange={() =>
                              setActiveStaff({
                                ...ActiveStaff,
                                building: ActiveStaff.building.includes(
                                  building.id
                                )
                                  ? ActiveStaff.building.filter(
                                      (entry) => entry !== building.id
                                    )
                                  : [...ActiveStaff.building, building.id],
                              })
                            }
                            checked={ActiveStaff.building.includes(building.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedStaff()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="staffStatus"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                {NewStatus == 1 ? "Activate" : "Block"} {ActiveStaff.firstname}{" "}
                {ActiveStaff.lastname} {ActiveStaff.surname} on {Authorization}
              </h5>
            </div>
            <div className="row justify-content-around">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => updateAuthorizations()}
                  >
                    Yes
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="removeStaff"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Remove {ActiveStaff.firstname} {ActiveStaff.lastname}{" "}
                {ActiveStaff.surname}
              </h5>
            </div>
            <div className="row justify-content-around">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedStaff()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{ overflowX: "scroll", paddingBottom: "60vh" }}
      >
        <hr />

        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="column">Firstname</th>
              <th scope="column">Lastname</th>
              <th scope="column">Surname</th>
              <th scope="column">IdNo</th>
              <th scope="column">Email</th>
              <th scope="column">Contact</th>
              <th scope="column">Building</th>
              <th scope="column">Authorizations</th>
              <th scope="column">Edit</th>
              <th scope="column">Delete</th>
            </tr>
          </thead>
          {StaffsDisplay}
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  staffs: state.staff.staffs,
  visitor: state.visitor.visitor,
  Buildings: state.Building.Buildings,
});

export default connect(mapStateToProps, {
  updatingStaff,
  fetchingStaffs,
  ReplacingStaff,
})(DisplayStaffs);
