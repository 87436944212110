import React, { useEffect } from "react";
import { appName, motto } from "../../constants/config";
import logo from "../../images/houselogo.jpg";
import { withRouter } from "react-router-dom";
import StaffAuthForm from "./StaffAuthForm";
import { connect } from "react-redux";
import { updateViewed } from "../../actions/viewedActions";

const StaffAuth = (props) => {
  useEffect(() => {
    props.updateViewed("app");
  }, []);

  return (
    <>
      <nav class="navbar fixed-top navbar-light bg-primary d-md-none d-lg-none">
        <span
          class="navbar-brand"
          onClick={() => {
            props.history.push("/");
          }}
        >
          <img
            src={logo}
            width="50"
            height="50"
            class="d-inline-block align-center rounded-pill mb-4"
            alt="Zidi-Capital-Sacco-Logo"
          />
          <div className="d-inline-block ml-4">
            {" "}
            <p className="text-light my-0" style={{ fontSize: 12 }}>
              {appName}
            </p>{" "}
            <p
              className="text-light text-center small my-0"
              style={{ fontSize: 8 }}
            >
              {motto}
            </p>
          </div>
        </span>

        <div class="collapse navbar-collapse" id="navbarNavDropdown"></div>
      </nav>
      <div className="bg-primary headerContainer  d-none d-sm-none d-md-block d-lg-block">
        <div className="header">
          <div style={{ width: "10%" }}>
            <img
              src={logo}
              className="float-left logo-image"
              alt="zidi-capiatl-sacco-logo"
              onClick={() => {
                props.history.push("/");
              }}
            />
          </div>
          <div style={{ width: "90%", marginLeft: "110px" }}>
            <p className="text-center">
              <span className="title text-capitalize">{appName}</span> <br />
              <span
                className="subtitle text-capitalize"
                style={{ paddingRight: "70px" }}
              >
                {motto}
              </span>
            </p>
          </div>
        </div>
        <div
          className="d-flex justify-content-around align-items-center mt-4 pt-4"
          style={{ width: "100vw", background: "rgb(141, 9, 141)" }}
        ></div>
      </div>

      <div className="theme" style={{ paddingTop: "40px" }}>
        <div
          className="row justify-content-center"
          style={{ marginTop: "30px" }}
        >
          <div className="col-12 col-sm-8 col-md-6 col-lg-4 d-flex justify-content-center">
            <StaffAuthForm LoginType={"Staff Sign In"}></StaffAuthForm>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateViewed })(
  withRouter(StaffAuth)
);
