import {
  ADD_BRANCH,
  FETCH_BRANCHES,
  UPDATE_BRANCH,
  DELETE_BRANCH,
} from "../actions/types";

const initialState = {
  branches: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRANCHES:
      return { ...state, branches: action.payload };

    case ADD_BRANCH:
      return { ...state, branches: [action.payload, ...state.branches] };

    case UPDATE_BRANCH:
      var newBranches = [...state.branches];
      var oldBranch = newBranches.find(
        (branch) => branch.linker == action.payload.linker
      );
      var Index = newBranches.indexOf(oldBranch);
      newBranches.splice(Index, 1, action.payload);
      return {
        ...state,
        branches: [...newBranches],
      };
    case DELETE_BRANCH:
      var newBranches = [...state.branches];

      return {
        ...state,
        branches: [
          ...newBranches.filter((branch) => branch.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
