import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingLandlord,
  updatingLandlord,
  deletingLandlord,
} from "../../actions/LandlordActions";
import swal from "sweetalert";
import { app } from "../../constants/config";

const AddLandlords = (props) => {
  const [Landlords, setLandlords] = useState([]);
  const [Saving, setSaving] = useState(false);

  const updateLandlords = (value, index, field) => {
    Landlords[index] = { ...Landlords[index], [field]: value };
    setLandlords([...Landlords]);
  };

  const RemoveLandlord = (index) => {
    let newLandlords = [...Landlords];
    newLandlords.splice(index, 1);
    setLandlords([...newLandlords]);
  };

  const AddLandlordnput = () => {
    setLandlords([
      ...Landlords,
      { name: "", description: "", linker: Date.now() },
    ]);
  };

  const SubmitLandlords = (Landlord) => {
    setSaving(true);
    fetch(`${app}/landlord/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Landlord,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingLandlord(details);
          setLandlords([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveLandlords = () => {
    Landlords.forEach((Landlord, index) => {
      if (/\S/.test(Landlord)) {
        SubmitLandlords({
          ...Landlord,
        });
      }
    });
  };

  const LandlordOutput = Landlords.map((Landlord, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveLandlord(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Name</label>
              <input
                placeholder="Enter Landlord Name"
                value={Landlord.name}
                onChange={(e) => updateLandlords(e.target.value, index, "name")}
                className="rounded-pill form-control"
              />

              <label className="mt-2">Landlord Description</label>
              <textarea
                placeholder="Enter Landlord Description eg account No"
                value={Landlord.description}
                onChange={(e) =>
                  updateLandlords(e.target.value, index, "description")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      {LandlordOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Landlords.length < 1 ? (
            <button
              className="btn-sm btn-info rounded-pill"
              onClick={AddLandlordnput}
            >
              Add Landlord
            </button>
          ) : Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveLandlords}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingLandlord,
  updatingLandlord,
  deletingLandlord,
})(AddLandlords);
