import {
  ADD_EXPENSE,
  FETCH_EXPENSES,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
} from "./types";

export const fetchingExpenses = (expenses) => (dispatch) => {
  dispatch({ type: FETCH_EXPENSES, payload: expenses });
};

export const addingExpense = (expense) => (dispatch) => {
  dispatch({ type: ADD_EXPENSE, payload: expense });
};

export const updatingExpense = (expense) => (dispatch) => {
  dispatch({ type: UPDATE_EXPENSE, payload: expense });
};

export const deletingExpense = (expenseId) => (dispatch) => {
  dispatch({ type: DELETE_EXPENSE, payload: expenseId });
};
