import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingHouses } from "../../actions/houseActions";
import DisplayHouses from "../Houses/DisplayHouses";
import NoHouses from "../Houses/NoHouses";
import AddHouses from "../Houses/AddHouses";

const BuildingHouses = (props) => {
  useEffect(() => {
    FetchHouses();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState([]);

  const FetchHouses = () => {
    setLoading(true);
    fetch(`${app}/house/building`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        buildingId: window.location.href.split("/").pop(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingHouses(details);
        } else {
          props.fetchingHouses([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingHouses([]);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetBuilding = (buildingId) => {
    let building = props.Buildings.find(
      (building) => building.id == buildingId
    );
    return building ? building : { name: "none", id: 0 };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Houses.length > 0 ? (
    <DisplayHouses />
  ) : (
    <NoHouses />
  );

  return (
    <>
      <p className="h6 text-center text-primary">
        <strong>
          {GetBuilding(window.location.href.split("/").pop()).name} Building
          Houses
        </strong>
      </p>
      <AddHouses></AddHouses>
      {Status}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Houses: state.House.Houses,
  Buildings: state.Building.Buildings,
});

export default connect(mapStateToProps, {
  fetchingHouses,
})(BuildingHouses);
