import React from "react";

const NoLandlords = () => {
  return (
    <div className="jumbotron justify-content-center">
      <h3 className="h3 text-center text-primary">No Landlords</h3>
    </div>
  );
};

export default NoLandlords;
