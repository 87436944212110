import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addingStaff, ReplacingStaff } from "../../../actions/staffActions";
import swal from "sweetalert";
import { app } from "../../../constants/config";
import Countries from "../../../Countries";

const AddStaffs = (props) => {
  const [Staffs, setStaffs] = useState([]);
  const [Country, setCountry] = useState({
    name: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dial_code: "+254",
  });
  const [Saving, setSaving] = useState(false);

  useEffect(() => {
    if (props.visitor.country) {
      let country = Countries.find(
        (country) =>
          country.code.toLowerCase() == props.visitor.country.toLowerCase()
      );
      setCountry(country);
    }
  }, [props.visitor]);

  const updateStaffs = (value, index, property) => {
    Staffs[index][property] = value;
    setStaffs([...Staffs]);
  };

  const UpdateAuthorization = (authorization, index) => {
    Staffs[index][authorization].checked =
      !Staffs[index][authorization].checked;
    setStaffs([...Staffs]);
  };

  const RemoveStaff = (index) => {
    let newStaffs = [...Staffs];
    newStaffs.splice(index, 1);
    setStaffs([...newStaffs]);
  };

  const AddStaffInput = () => {
    setStaffs([
      ...Staffs,
      {
        firstname: "",
        lastname: "",
        surname: "",
        photo: "",
        contact: Country.dial_code,
        email: "",
        idNo: "",
        password: "",
        building: [],
        admin: { checked: false, value: "admin" },
        expenses: { checked: false, value: "expenses" },
        tenants: { checked: false, value: "tenants" },
        payments: { checked: false, value: "tenants" },
        landlords: { checked: false, value: "landlords" },
        buildings: { checked: false, value: "buildings" },
        houses: { checked: false, value: "houses" },
        reports: { checked: false, value: "reports" },
        invoices: { checked: false, value: "invoices" },
        branch: { name: "none", id: 0 },
        linker: Date.now(),
      },
    ]);
  };

  const SubmitStaff = (response) => {
    setSaving(true);
    fetch(`${app}/staff/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...response,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code != 2) {
          //add new staff in the staffs state
          props.addingStaff({ ...details });
          setSaving(false);
          setStaffs([]);
        } else {
          setSaving(false);
          swal({
            title: "Server Error!",
            span: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);

        swal({
          title: "Network Error!",
          span: "Please try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveStaffs = () => {
    Staffs.forEach((staff) => {
      if (
        /\S/.test(staff.firstname) ||
        /\S/.test(staff.lastname) ||
        /\S/.test(staff.surname)
      ) {
        let contact;
        if (/\S/.test(staff.contact)) {
          if (staff.contact.charAt(0) !== "+") {
            contact = Country.dial_code + staff.contact.substr(1);
          } else {
            contact = staff.contact;
          }
        }
        let response = {
          firstname: staff.firstname,
          lastname: staff.lastname,
          surname: staff.surname,
          photo: staff.photo,
          password: staff.password,
          email: staff.email.toLowerCase(),
          contact,
          idNo: staff.idNo,
          building: JSON.stringify(staff.building),
          branches_id: staff.branch.id,
          linker: staff.linker,
          admin: staff.admin.checked ? 1 : 0,
          expenses: staff.expenses.checked ? 1 : 0,
          payments: staff.expenses.checked ? 1 : 0,
          tenants: staff.tenants.checked ? 1 : 0,
          landlords: staff.landlords.checked ? 1 : 0,
          buildings: staff.buildings.checked ? 1 : 0,
          houses: staff.houses.checked ? 1 : 0,
          reports: staff.reports.checked ? 1 : 0,
          invoices: staff.invoices.checked ? 1 : 0,
          status: "addStaff",
        };

        SubmitStaff(response);
      }
    });
  };

  const StaffOutput = Staffs.map((staff, index) => {
    return (
      <div key={index}>
        <div className="row justify-content-center">
          <div className="col-12 d-flex  justify-content-end align-items-center">
            <button
              className="btn-sm btn-danger rounded-circle"
              onClick={() => RemoveStaff(index)}
            >
              X
            </button>
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Id No</label>
            <input
              placeholder="Enter Id No"
              value={staff.idNo}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "idNo")}
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Firstname</label>
            <input
              placeholder="Enter Firstname"
              value={staff.firstname}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "firstname")}
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Lastname</label>
            <input
              placeholder="Enter Lastname"
              value={staff.lastname}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "lastname")}
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Surname</label>
            <input
              placeholder="Enter surname"
              value={staff.surname}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "surname")}
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Contact</label>
            <input
              placeholder="Enter Contact"
              value={staff.contact}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "contact")}
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Email</label>
            <input
              placeholder="Enter email"
              value={staff.email}
              className="rounded-pill form-control"
              onChange={(e) =>
                updateStaffs(e.target.value.toLowerCase(), index, "email")
              }
            />
          </div>
          <div className="col-12 col-md-4  justify-content-center">
            <label>Staff Password</label>
            <input
              placeholder="Enter Password"
              value={staff.password}
              className="rounded-pill form-control"
              onChange={(e) => updateStaffs(e.target.value, index, "password")}
            />
          </div>
          <div className="col-12 col-md-12  ">
            <p className="h6 text-primary text-center">Select Building </p>
            <div className="row justify-content-around  mx-2">
              {props.Buildings.map((building) => (
                <div
                  className="col-12 col-md-6 d-flex justify-content-between justify-content-md-center justify-content-lg-center"
                  key={building.id}
                >
                  <span style={{ marginRight: 5 }}>{building.name}</span>
                  <input
                    type="checkbox"
                    onChange={() =>
                      updateStaffs(
                        staff.building.includes(building.id)
                          ? staff.building.filter(
                              (entry) => entry !== building.id
                            )
                          : [...staff.building, building.id],
                        index,
                        "building"
                      )
                    }
                    checked={staff.building.includes(building.id)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="col-12  justify-content-center">
            <p className="h6 text-primary text-center">
              Select Authorization On
            </p>

            <div className="d-sm-block d-md-flex justify-content-center">
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                {" "}
                <span style={{ marginRight: 5 }}>Staffs</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("admin", index)}
                  checked={staff.admin.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                {" "}
                <span style={{ marginRight: 5 }}>Reports</span>{" "}
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("reports", index)}
                  checked={staff.reports.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Landlords</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("landlords", index)}
                  checked={staff.landlords.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Buildings</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("buildings", index)}
                  checked={staff.buildings.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Houses</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("houses", index)}
                  checked={staff.houses.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Tenants</span>{" "}
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("tenants", index)}
                  checked={staff.tenants.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Invoices</span>{" "}
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("invoices", index)}
                  checked={staff.invoices.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Payments</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("payments", index)}
                  checked={staff.payments.checked}
                />
              </div>
              <div className="mx-2 d-flex justify-content-between d-md-inline-block d-lg-inline-block">
                <span style={{ marginRight: 5 }}>Expenses</span>
                <input
                  type="checkbox"
                  onChange={() => UpdateAuthorization("expenses", index)}
                  checked={staff.expenses.checked}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  });

  return (
    <div>
      {StaffOutput}

      <div className="d-flex justify-content-center">
        {Staffs.length < 1 ? (
          <button
            className="btn btn-info rounded-circle mx-3"
            onClick={AddStaffInput}
          >
            Add Staff
          </button>
        ) : Saving ? (
          <p className="text-center">
            <span className="spinner-border text-primary "></span>
          </p>
        ) : (
          <button
            onClick={SaveStaffs}
            className="btn btn-primary rounded-circle mx-3"
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  branches: state.branch.branches,
  staffs: state.staff.staffs,
  visitor: state.visitor.visitor,
  Buildings: state.Building.Buildings,
});

export default connect(mapStateToProps, { addingStaff, ReplacingStaff })(
  AddStaffs
);
