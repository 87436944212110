import React, { useEffect } from "react";
import { connect } from "react-redux";
import DisplayTenants from "./DisplayTenants";
import NoTenants from "./NoTenants";
import AddTenants from "./AddTenants";
import { app } from "../../constants/config";
import { fetchingTenants } from "../../actions/tenantActions";

const Tenants = (props) => {
  useEffect(() => {
    FetchTenants();
  }, []);

  const FetchTenants = () => {
    fetch(`${app}/tenant/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getTime(),
        toDate:
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            ).getDate(),
        month: `${new Date().getFullYear()}-${(
          "0" +
          (new Date().getMonth() + 1)
        ).slice(-2)}`,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 2) {
        } else {
          props.fetchingTenants(details);
        }
      })
      .catch((err) => {});
  };

  const Status = props.Tenants.length > 0 ? <DisplayTenants /> : <NoTenants />;

  return (
    <>
      <AddTenants></AddTenants>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Tenants: state.Tenant.Tenants,
  banker: state.auth.banker,
});

export default connect(mapStateToProps, { fetchingTenants })(Tenants);
