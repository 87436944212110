import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingInvoice,
  updatingInvoice,
  deletingInvoice,
  fetchingInvoices,
} from "../../actions/invoiceActions";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";
import DisplayInvoices from "./DisplayInvoices";

const AddInvoices = (props) => {
  const [Invoices, setInvoices] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");
  const [Building, setBuilding] = useState("");
  const [Amount, setAmount] = useState(0);
  const [Name, setName] = useState("");
  const [Details, setDetails] = useState("");

  const updateInvoices = (value, index, field) => {
    Invoices[index] = { ...Invoices[index], [field]: value };
    setInvoices([...Invoices]);
  };

  const GetBuilding = () => {
    let building = props.Buildings.find((building) => building.id == Building);
    return building ? building.name : Building == "all" ? "All" : "Unknown";
  };

  const InitializeBulk = () => {
    let linker = Date.now();
    let invoiceInputs = [...props.Houses]
      .filter((house) => house.buildings_id == Building)
      .map((house, index) => ({
        tenants: house.tenants,
        name: house.name,
        amount: Name == "rent" ? house.rent : Amount,
        selected: false,
        houses_id: house.id,
        tenants_id:
          (house.tenants || []).length > 0
            ? `${house.tenants[house.tenants.length - 1].id}`
            : "none",
        ref_payment: `${
          (house.tenants || []).length > 0
            ? `${house.tenants[house.tenants.length - 1].id}`
            : "none"
        }${house.id}${Month}${Name}`,
        for: Name,
        status: 1,
        month: Month,
        details: Details,
        linker: linker + index,
        bankerEmail: props.banker.user.original.email,
      }));
    setInvoices(invoiceInputs);
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0 };
  };

  const FetchInvoices = () => {
    setLoading(true);
    fetch(`${app}/invoice/houses`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        month: Month,
        houses_id: [...props.Houses]
          .filter((house) => house.buildings_id == Building)
          .map((house) => house.id),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 2) {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        } else {
          props.fetchingInvoices(details);
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SubmitInvoices = () => {
    setSaving(true);
    let invoices = Invoices.filter((invoice) => invoice.selected).map(
      (invoice) => {
        delete invoice.selected;
        delete invoice.tenants;
        delete invoice.name;
        return { ...invoice };
      }
    );
    fetch(`${app}/invoice/bulk`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        invoices,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2 && details.message == true) {
          swal({
            title: "Invoice Successful",
            text: "",
            icon: "success",
            timer: 3000,
          });
          setInvoices([]);
          FetchInvoices();
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const BulkDetails = (
    <div>
      {Invoices.length < 1 ? (
        props.banker.credentials.length > 0 ? (
          <>
            {" "}
            <label>Select Building</label>
            <select
              className="rounded-pill form-control"
              onChange={(e) => setBuilding(e.target.value)}
              required
            >
              <option value="">Select Building</option>
              {(props.banker.credentials[0].building == "all"
                ? props.Buildings
                : props.Buildings.filter((building) =>
                    StaffBuildings(
                      props.banker.credentials[0].building
                    ).includes(building.id)
                  )
              ).map((building) => (
                <option key={building.id} value={building.id}>
                  {building.name}
                </option>
              ))}
            </select>
            <hr />
          </>
        ) : null
      ) : null}
      <label>Invoice For</label>
      <select
        className="rounded-pill form-control"
        required
        value={Name}
        onChange={(e) => setName(e.target.value)}
      >
        <option value="">Select Particular</option>
        <option value="rent">Rent</option>
        <option value="water">Water Bill</option>
        <option value="electricity">Electricity Bill</option>
        <option value="expenses">Other Expenses</option>
      </select>
      <hr />
      <label>For Month</label>
      <input
        type="month"
        required
        className="rounded-pill form-control"
        placeholder="Select Month"
        onChange={(e) => setMonth(e.target.value)}
        value={Month}
      />
      <hr />
      <label>Amount</label>
      {Name != "rent" ? (
        <input
          required
          placeholder="Enter Amount To Invoice"
          value={Amount}
          onChange={(e) =>
            setAmount(
              e.target.value.slice(-1) == "-" ||
                e.target.value.slice(-1) == "." ||
                !isNaN(e.target.value.slice(-1))
                ? e.target.value
                : e.target.value.slice(0, -1)
            )
          }
          className="rounded-pill form-control"
        />
      ) : (
        <p>Houses Default Rent will be added</p>
      )}
      <hr />
      <label className="mt-2">Invoice Other Details</label>
      <textarea
        required
        placeholder="Enter Invoice Details"
        value={Details}
        onChange={(e) => setDetails(e.target.value)}
        className="rounded-pill form-control"
      />
      <hr />
      <div className="d-flex justify-content-around">
        <button
          onClick={() => $(`#InvoiceDetails`).modal("hide")}
          className="btn-sm btn-info rounded-pill"
        >
          Cancel
        </button>
        <button className="btn-sm btn-primary rounded-pill" type="submit">
          Okay
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-around">
        {Loading ? (
          <p className="text-center">
            <span className="spinner-border text-primary "></span>
          </p>
        ) : (
          <button
            onClick={() => $(`#InvoiceDetails`).modal("show")}
            className="btn-sm btn-info rounded-pill"
          >
            Bulk Invoice
          </button>
        )}
      </div>

      {Invoices.length > 0 ? (
        <>
          <p className="h6 text-primary text-center text-capitalize">
            Invoice for {Name} For {GetBuilding().name} {Month}
          </p>
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th scope="column">House No</th>
                <th scope="column">Tenant</th>
                <th>Amount</th>
                <th>Mark</th>
              </tr>
            </thead>
            <tbody>
              {Invoices.map((invoice, index) => (
                <tr>
                  <td>{invoice.name}</td>
                  <td>
                    {(invoice.tenants || []).length > 0
                      ? `${invoice.tenants[invoice.tenants.length - 1].name}`
                      : "none"}
                  </td>
                  <td>
                    <input
                      placeholder="amount"
                      required
                      type="number"
                      value={invoice.amount}
                      onChange={(e) => {
                        updateInvoices(e.target.value, index, "amount");
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      isChecked={invoice.selected}
                      onChange={() => {
                        updateInvoices(!invoice.selected, index, "selected");
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}>
                  <div className="d-flex justify-content-around">
                    {Saving ? (
                      <p className="text-center">
                        <span className="spinner-border text-primary "></span>
                      </p>
                    ) : Invoices.length > 0 ? (
                      <button
                        className="btn-sm btn-primary rounded-pill"
                        onClick={SubmitInvoices}
                      >
                        Save
                      </button>
                    ) : null}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : null}

      <div
        id="InvoiceDetails"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">Invoice Details</h5>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                $(`#InvoiceDetails`).modal("hide");
                InitializeBulk();
              }}
              className="mx-2"
            >
              {BulkDetails}
            </form>
          </div>
        </div>
      </div>
      {Invoices.length < 1 && !Loading ? (
        <DisplayInvoices></DisplayInvoices>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  activeAuthorization: state.auth.activeAuthorization,
  Buildings: state.Building.Buildings,
  Houses: state.House.Houses,
});

export default connect(mapStateToProps, {
  addingInvoice,
  updatingInvoice,
  deletingInvoice,
  fetchingInvoices,
})(AddInvoices);
