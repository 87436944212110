import React from "react";
import { connect } from "react-redux";
import DisplayPayments from "./DisplayPayments";
import NoPayments from "./NoPayments";
import AddPayments from "./AddPayments";

const Payments = (props) => {
  const Status =
    props.Payments.length > 0 ? <DisplayPayments /> : <NoPayments />;

  return (
    <>
      <AddPayments></AddPayments>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Payments: state.Payment.Payments,
});

export default connect(mapStateToProps, {})(Payments);
