import { ADD_HOUSE, FETCH_HOUSES, UPDATE_HOUSE, DELETE_HOUSE } from "./types";

export const fetchingHouses = (houses) => (dispatch) => {
  dispatch({ type: FETCH_HOUSES, payload: houses });
};

export const addingHouse = (house) => (dispatch) => {
  dispatch({ type: ADD_HOUSE, payload: house });
};

export const updatingHouse = (house) => (dispatch) => {
  dispatch({ type: UPDATE_HOUSE, payload: house });
};

export const deletingHouse = (houseId) => (dispatch) => {
  dispatch({ type: DELETE_HOUSE, payload: houseId });
};
