import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";
import Details from "./Details";

const Rental = (props) => {
  useEffect(() => {
    $("#fetchReport").modal("show");
  }, []);

  const [Month, setMonth] = useState("");
  const [Building, setBuilding] = useState(
    props.banker.credentials.length > 0
      ? props.banker.credentials[0].building
      : null
  );
  const [Housing, setHousing] = useState(false);
  const [Invoicing, setInvoicing] = useState(false);
  const [Paying, setPaying] = useState(false);
  const [Houses, setHouses] = useState([]);
  const [Invoices, setInvoices] = useState([]);
  const [Payments, setPayments] = useState([]);
  const [Summary, setSummary] = useState("all");
  const [Bal, setBal] = useState("all");

  const FetchReport = () => {
    FetchData("house", Building == "all" ? "get" : "building");
    FetchData("payment", "month");
    FetchData("invoice", "month");
  };

  const FetchData = (type, extent) => {
    type == "house"
      ? setHousing(true)
      : type == "invoice"
      ? setInvoicing(true)
      : setPaying(true);
    fetch(`${app}/${type}/${extent}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        buildingId: Building,
        month: Month,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        type == "house"
          ? setHousing(false)
          : type == "invoice"
          ? setInvoicing(false)
          : setPaying(false);
        if (details.state != 2) {
          type == "house"
            ? setHouses(details)
            : type == "invoice"
            ? setInvoices(details)
            : setPayments(details);
          if (!Housing && !Invoicing && !Paying) {
            $("#fetchReport").modal("hide");
          }
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        type == "house"
          ? setHousing(false)
          : type == "invoice"
          ? setInvoicing(false)
          : setPaying(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetBuilding = () => {
    let building = props.Buildings.find((building) => building.id == Building);
    return building ? building.name : Building == "all" ? "All" : "Unknown";
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  return (
    <div>
      <div className="d-flex justify-content-around my-2">
        <button
          className="btn btn-md btn-rounded-pill btn-primary mx-4"
          onClick={() => $("#fetchReport").modal("show")}
        >
          View Report
        </button>
      </div>
      <div className="d-flex justify-content-center my-2">
        {Houses.length > 0 ? (
          <select onChange={(e) => setSummary(e.target.value)} className="mx-4">
            <option value="all">All Invoices</option>
            <option value="rent">Rent</option>
            <option value="deposit">Deposit</option>
            <option value="water">Water</option>
            <option value="electricity">Electricity</option>
            <option value="expenses">Other Expenses</option>
          </select>
        ) : null}
        {Houses.length > 0 ? (
          <select onChange={(e) => setBal(e.target.value)} className="mx-4">
            <option value="all">All Bal</option>
            <option value="paid">Paid</option>
            <option value="bal">Bal</option>
          </select>
        ) : null}
      </div>
      <div
        id="fetchReport"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">View Rental Summary</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Select Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                  {props.banker.credentials.length > 0 ? (
                    <>
                      {" "}
                      <label>Select Building</label>
                      <select
                        className="rounded-pill form-control"
                        onChange={(e) => setBuilding(e.target.value)}
                      >
                        <option value={0}>Select Building</option>
                        {props.banker.credentials[0].building == "all" ? (
                          <option value={"all"}>All Buildings</option>
                        ) : null}
                        {(props.banker.credentials[0].building == "all"
                          ? props.Buildings
                          : props.Buildings.filter((building) =>
                              StaffBuildings(
                                props.banker.credentials[0].building
                              ).includes(building.id)
                            )
                        ).map((building) => (
                          <option key={building.id} value={building.id}>
                            {building.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}
                </div>

                <div className="col-12 d-flex justify-content-around my-4">
                  <button
                    className="btn-xs btn-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!Housing && !Invoicing && !Paying ? (
                    <button
                      className="btn-xs btn-primary rounded-pill"
                      onClick={() => FetchReport()}
                    >
                      View
                    </button>
                  ) : (
                    <span className="spinner-border text-primary"></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Houses.length < 1 ? (
        <p className="h3 text-primary text-center">No Records</p>
      ) : (
        <Details
          houses={Houses}
          invoices={Invoices}
          payments={Payments}
          month={Month}
          building={GetBuilding()}
          buildingId={Building}
          summary={
            Summary == "all"
              ? ["rent", "deposit", "electricity", "water", "expenses"]
              : ["rent", "deposit", "electricity", "water", "expenses"].filter(
                  (item) => item == Summary
                )
          }
          bal={Bal}
        ></Details>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Buildings: state.Building.Buildings,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {})(Rental);
