import {
  ADD_GROUP,
  FETCH_GROUPS,
  UPDATE_GROUP,
  DELETE_GROUP,
} from "../actions/types";

const initialState = {
  groups: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS:
      return { ...state, groups: action.payload };

    case ADD_GROUP:
      return { ...state, groups: [action.payload, ...state.groups] };

    case UPDATE_GROUP:
      var newGroups = [...state.groups];
      var oldGroup = newGroups.find(
        (group) => group.linker == action.payload.linker
      );
      var Index = newGroups.indexOf(oldGroup);
      newGroups.splice(Index, 1, action.payload);
      return {
        ...state,
        groups: [...newGroups],
      };

    case DELETE_GROUP:
      var newGroups = [...state.groups];

      return {
        ...state,
        groups: [
          ...newGroups.filter((group) => group.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
