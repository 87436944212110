import React from "react";
import { connect } from "react-redux";
import DisplayExpenses from "./DisplayExpenses";
import NoExpenses from "./NoExpenses";
import AddExpenses from "./AddExpenses";

const Expenses = (props) => {
  const Status =
    props.Expenses.length > 0 ? <DisplayExpenses /> : <NoExpenses />;

  return (
    <>
      <AddExpenses></AddExpenses>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Expenses: state.Expense.Expenses,
});

export default connect(mapStateToProps, {})(Expenses);
