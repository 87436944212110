import {
  ADD_BUILDING,
  FETCH_BUILDINGS,
  UPDATE_BUILDING,
  DELETE_BUILDING,
} from "../actions/types";

const initialState = {
  Buildings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUILDINGS:
      return { ...state, Buildings: action.payload };

    case ADD_BUILDING:
      return { ...state, Buildings: [action.payload, ...state.Buildings] };

    case UPDATE_BUILDING:
      var newBuildings = [...state.Buildings];
      var oldBranch = newBuildings.find(
        (building) => building.linker == action.payload.linker
      );
      var Index = newBuildings.indexOf(oldBranch);
      newBuildings.splice(Index, 1, action.payload);
      return {
        ...state,
        Buildings: [...newBuildings],
      };
    case DELETE_BUILDING:
      var newBuildings = [...state.Buildings];

      return {
        ...state,
        Buildings: [
          ...newBuildings.filter(
            (building) => building.linker != action.payload
          ),
        ],
      };

    default:
      return state;
  }
};
