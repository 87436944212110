import React, { useState } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import InstitutionHeader from "./InstitutionHeader";
import InstitutionHome from "./InstitutionHome";
import InstitutionNav from "./InstitutionNav";
import Landlords from "../Landlords/Landlords";
import LandlordBuildings from "../Landlords/LandlordBuildings";
import LandlordRepayment from "../Landlords/LandlordRepayment";
import Buildings from "../Buildings/Buildings";
import Houses from "../Houses/Houses";
import Tenants from "../Tenants/Tenants";
import Staffs from "../Admin/Staffs/Staffs";
import Expenses from "../Expenses/Expenses";
import Invoices from "../Invoices/Invoices";
import Payments from "../Payments/Payments";
import Repayments from "../Repayments/Repayments";
import BuildingHouses from "../Buildings/BuildingHouses";
import HouseTenants from "../Houses/HouseTenants";
import HouseInvoices from "../Houses/HouseInvoices";
import HousePayments from "../Houses/HousePayments";
import TenantInvoices from "../Tenants/TenantInvoices";
import TenantPayments from "../Tenants/TenantPayments";
import Rental from "../Rental/Rental";
import BulkInvoicing from "../Invoices/BulkInvoicing";
import Modes from "../Modes/Modes";
import Summary from "../Summary/Summary";

const Institution = (props) => {
  const { url } = useRouteMatch();

  const [Toggled, setToggled] = useState(true);

  return (
    <div className="theme">
      <InstitutionHeader
        sideBarToggle={() => setToggled(!Toggled)}
      ></InstitutionHeader>
      <div className="content">
        <div id="wrapper" className={Toggled ? "toggled" : "closed"}>
          <InstitutionNav
            sideBarToggle={() => setToggled(!Toggled)}
          ></InstitutionNav>
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <Route exact path={url}>
                <InstitutionHome
                  sideBarToggle={() => setToggled(!Toggled)}
                ></InstitutionHome>
              </Route>
              <Route path={`${url}/landlords`}>
                <Landlords></Landlords>
              </Route>
              <Route path={`${url}/buildings`}>
                <Buildings></Buildings>
              </Route>
              <Route path={`${url}/modes`}>
                <Modes></Modes>
              </Route>
              <Route path={`${url}/houses`}>
                <Houses></Houses>
              </Route>
              <Route path={`${url}/tenants`}>
                <Tenants></Tenants>
              </Route>
              <Route path={`${url}/staffs`}>
                <Staffs></Staffs>
              </Route>
              <Route path={`${url}/expenses`}>
                <Expenses></Expenses>
              </Route>
              <Route path={`${url}/invoices`}>
                <Invoices></Invoices>
              </Route>
              <Route path={`${url}/bulk-invoicing`}>
                <BulkInvoicing></BulkInvoicing>
              </Route>
              <Route path={`${url}/payments`}>
                <Payments></Payments>
              </Route>
              <Route path={`${url}/repayments`}>
                <Repayments></Repayments>
              </Route>
              <Route path={`${url}/landlord/buildings/:landlordId`}>
                <LandlordBuildings></LandlordBuildings>
              </Route>
              <Route path={`${url}/landlord/payment/:landlordId`}>
                <LandlordRepayment></LandlordRepayment>
              </Route>
              <Route path={`${url}/building/houses/:buildingId`}>
                <BuildingHouses></BuildingHouses>
              </Route>
              <Route path={`${url}/house/tenants/:houseId`}>
                <HouseTenants></HouseTenants>
              </Route>
              <Route path={`${url}/house/invoices/:houseId`}>
                <HouseInvoices></HouseInvoices>
              </Route>
              <Route path={`${url}/house/payments/:houseId`}>
                <HousePayments></HousePayments>
              </Route>
              <Route path={`${url}/tenant/invoices/:tenantId`}>
                <TenantInvoices></TenantInvoices>
              </Route>
              <Route path={`${url}/tenant/payments/:tenantId`}>
                <TenantPayments></TenantPayments>
              </Route>
              <Route path={`${url}/financial`}>
                <Summary></Summary>
              </Route>
              <Route path={`${url}/rental`}>
                <Rental></Rental>
              </Route>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {})(Institution);
