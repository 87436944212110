import React, { useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { deletingPayment, updatingPayment } from "../../actions/paymentActions";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Download from "../Download/Download";
import * as XLSX from "xlsx/xlsx.mjs";

const DisplayPayments = (props) => {
  const [ActivePayment, setActivePayment] = useState({
    id: "",
    bankId: "",
    linker: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
  });
  const [Building, setBuilding] = useState("all");
  const [Mode, setMode] = useState("all");

  const [Deleting, setDeleting] = useState(false);
  const [Editting, setEditting] = useState(false);

  const saveEdittedPayment = () => {
    if (/\S/.test(ActivePayment.name)) {
      setEditting(true);
      fetch(`${app}/payment/edit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${props.banker.token}`,
        },
        body: JSON.stringify({
          ...ActivePayment,
          PaymentId: ActivePayment.id,
          houses_id: GetTenant(ActivePayment.tenants_id).houses_id,
          landlords_id: props.banker.user.original.landlord
            ? props.banker.user.original.landlord.id
            : props.banker.credentials.length > 0
            ? props.banker.credentials[0].landlords_id
            : 0,
        }),
      })
        .then((res) => res.json())
        .then((details) => {
          setEditting(false);
          $("#editPayment").modal("hide");
          if (details.code != 2) {
            props.updatingPayment(details);
          } else {
            swal({
              title: "Server Error!",
              text: "Please try again",
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          setEditting(false);
          swal({
            title: "Connection Error!",
            text: "Check your connection and try again",
            icon: "error",
            timer: 3000,
          });
        });
    } else {
      swal({
        title: "Empty Name!",
        text: "Ensure name is filled",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0, housesId: 0 };
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const GetBuilding = () => {
    let building = props.Buildings.find((building) => building.id == Building);
    return building ? building.name : Building == "all" ? "All" : "Unknown";
  };

  const StaffBuildings = (staffbuildings) => {
    let buildings = [];
    try {
      buildings =
        JSON.parse(staffbuildings) == null ? [] : JSON.parse(staffbuildings);
    } catch {}
    return buildings;
  };

  const FilterPayments = props.Payments.filter(
    (payment) =>
      (Building == "all"
        ? true
        : Building == GetHouse(payment.houses_id).buildings_id) &&
      (Mode == "all" ? true : Mode == payment.mode)
  );

  const ExportToExcel = (type) => {
    console.log("sam");
    var elt = document.getElementById("payments");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });

    return XLSX.writeFile(
      wb,
      `${Mode}-Payments-for-${GetBuilding()}-${
        FilterPayments.length > 0 ? FilterPayments[0].month : null
      }.` + (type || "xlsx")
    );
  };

  const saveDeletedPayment = () => {
    setDeleting(true);
    fetch(`${app}/payment/delete`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...ActivePayment,
        PaymentId: ActivePayment.id,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setDeleting(false);
        $("#deletePayment").modal("hide");
        if ((details.code = 2)) {
          props.deletingPayment(details.linker);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setDeleting(false);

        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const PaymentsDisplay = FilterPayments.map((Payment) => (
    <tr>
      <td>{Payment.for}</td>
      <td>{Payment.month}</td>
      <td>{GetTenant(Payment.tenants_id).name}</td>
      <td>{GetHouse(Payment.houses_id).name}</td>
      <td>{Payment.amount}</td>
      <td>{Payment.bankerEmail}</td>
      <td className="text-break">
        {
          new Date(parseInt(Payment.linker))
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")
            .split(" ")[0]
        }
      </td>
      <td>{Payment.details}</td>
      <td>
        {" "}
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => setActivePayment({ ...Payment })}
          data-toggle="modal"
          data-target="#editPayment"
        >
          <FontAwesomeIcon className="mx-2" icon={faEdit} />
        </button>
      </td>
      <td>
        {" "}
        <button
          className="btn-sm btn-danger rounded-pill"
          onClick={() => setActivePayment({ ...Payment })}
          data-toggle="modal"
          data-target="#deletePayment"
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      <div>
        <div>
          <table className="table table-bordered table-sm">
            <thead>
              <th scope="column" colSpan={5}>
                {props.banker.credentials.length > 0 ? (
                  <select
                    className="form-control"
                    onChange={(e) => setBuilding(e.target.value)}
                  >
                    {props.banker.credentials[0].building == "all" ? (
                      <option value={"all"}>All Buildings</option>
                    ) : null}
                    {(props.banker.credentials[0].building == "all"
                      ? props.Buildings
                      : props.Buildings.filter((building) =>
                          StaffBuildings(
                            props.banker.credentials[0].building
                          ).includes(building.id)
                        )
                    ).map((building) => (
                      <option key={building.id} value={building.id}>
                        {building.name}
                      </option>
                    ))}
                  </select>
                ) : null}{" "}
              </th>
              <th colSpan={5}>
                {" "}
                <select
                  className="form-control"
                  value={Mode}
                  onChange={(e) => setMode(e.target.value)}
                >
                  <option value="all">All Modes</option>
                  {props.Modes.map((mode) => (
                    <option value={mode.name}>{mode.name}</option>
                  ))}
                  <option value="cash">Cash</option>
                  <option value="bank">Bank</option>
                  <option value="electronic">Electronic/M-pesa</option>
                </select>{" "}
              </th>
            </thead>
          </table>
        </div>
        <div id="payments">
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="column" colSpan={2}>
                  {Building == "all"
                    ? "All"
                    : (
                        props.Buildings.find(
                          (building) => building.id == Building
                        ) || { name: "Unkown" }
                      ).name}{" "}
                  Building
                </th>
                <th colSpan={2} className="text-capitalize">
                  {Mode} Mode
                </th>
                <th scope="column" colSpan={3}>
                  Total
                </th>
                <th scope="column" colSpan={2}>
                  {FilterPayments.reduce((a, b) => +a + +b.amount, 0)}
                </th>
              </tr>
              <tr>
                <th scope="column" className="h6 ">
                  Name
                </th>
                <th scope="column" className="h6 ">
                  Month
                </th>
                <th scope="column" className="h6 ">
                  Tenant
                </th>
                <th scope="column" className="h6 ">
                  House
                </th>
                <th scope="column" className="h6 ">
                  Amount
                </th>
                <th scope="column" className="h6 ">
                  By
                </th>
                <th scope="column" className="h6 ">
                  Date
                </th>
                <th scope="column" className="h6 ">
                  Details
                </th>
                <th scope="column" className="h6 ">
                  Edit
                </th>
                <th scope="column" className="h6 ">
                  Delete
                </th>
              </tr>
            </thead>
            {PaymentsDisplay}
          </table>
        </div>
        <div className="d-flex justify-content-center">
          <button
            onClick={() => ExportToExcel("xlsx")}
            className="btn-sm btn-info rounded-pill"
          >
            Download
          </button>
          {/*<Download
            rootElementId={`payments`}
            downloadFileName={`${Mode}-Payments-for-${GetBuilding()}-${
              FilterPayments.length > 0 ? FilterPayments[0].month : null
            }`}
          ></Download>*/}
        </div>
      </div>
      <div
        id="editPayment"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Edit {ActivePayment.for} {ActivePayment.month} Payment
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Tenant </label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActivePayment({
                        ...ActivePayment,
                        tenants_id: e.target.value,
                      })
                    }
                  >
                    <option value={0}>Select Tenant</option>
                    {props.Tenants.map((tenant) => (
                      <option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </select>
                  <label>Payment For</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActivePayment({
                        ...ActivePayment,
                        for: e.target.value,
                      })
                    }
                  >
                    <option value="rent">Select Particular</option>
                    <option value="deposit">Deposit</option>
                    <option value="rent">Rent</option>
                    <option value="water">Water Bill</option>
                    <option value="electricity">Electricity Bill</option>
                    <option value="expenses">Other Expenses</option>
                  </select>
                  <label>Payment Mode</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      setActivePayment({
                        ...ActivePayment,
                        mode: e.target.value,
                      })
                    }
                  >
                    <option value="cash">Select Payment Mode</option>
                    {props.Modes.map((mode) => (
                      <option value={mode.name}>{mode.name}</option>
                    ))}
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                    <option value="electronic">Electronic/M-pesa</option>
                  </select>{" "}
                  <label>For Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) =>
                      setActivePayment({
                        ...ActivePayment,
                        month: e.target.value,
                      })
                    }
                  />
                  <label>Amount</label>
                  <input
                    placeholder="Enter Amount To Payment"
                    value={ActivePayment.amount}
                    onChange={(e) =>
                      setActivePayment({
                        ...ActivePayment,
                        amount:
                          e.target.value.slice(-1) == "-" ||
                          e.target.value.slice(-1) == "." ||
                          !isNaN(e.target.value.slice(-1))
                            ? e.target.value
                            : e.target.value.slice(0, -1),
                      })
                    }
                    className="rounded-pill form-control"
                  />
                  <div>
                    <label>Payment Details :</label>
                    <input
                      type="text"
                      className="rounded-pill form-control"
                      placeholder="Enter Payment Details"
                      value={ActivePayment.details}
                      onChange={(e) =>
                        setActivePayment({
                          ...ActivePayment,
                          details: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {!Editting ? (
                  <button
                    className="btn-xs btn-primary rounded-pill"
                    onClick={() => saveEdittedPayment()}
                  >
                    Save
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="deletePayment"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">
                Delete {ActivePayment.for} {ActivePayment.month} Payment
              </h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-around my-4">
                <button
                  className="btn-xs btn-danger rounded-pill"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                {!Deleting ? (
                  <button
                    className="btn-xs btn-warning rounded-pill"
                    onClick={() => saveDeletedPayment()}
                  >
                    Delete
                  </button>
                ) : (
                  <span className="spinner-border text-primary"></span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Payments: state.Payment.Payments,
  Tenants: state.Tenant.Tenants,
  Houses: state.House.Houses,
  Buildings: state.Building.Buildings,
  Modes: state.Mode.Modes,
});

export default connect(mapStateToProps, {
  deletingPayment,
  updatingPayment,
})(withRouter(DisplayPayments));
