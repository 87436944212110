import React, { useEffect } from "react";
import { connect } from "react-redux";
import DisplayModes from "./DisplayModes";
import NoModes from "./NoModes";
import AddModes from "./AddModes";
import { app } from "../../constants/config";
import { fetchingModes } from "../../actions/modeActions";

const Modes = (props) => {
  useEffect(() => {
    FetchingModes();
  }, []);

  const FetchingModes = () => {
    fetch(`${app}/mode/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getTime(),
        toDate:
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            ).getDate(),
        month: `${new Date().getFullYear()}-${(
          "0" +
          (new Date().getMonth() + 1)
        ).slice(-2)}`,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 2) {
        } else {
          props.fetchingModes(details);
        }
      })
      .catch((err) => {});
  };
  const Status = props.Modes.length > 0 ? <DisplayModes /> : <NoModes />;
  return (
    <>
      <AddModes></AddModes>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Modes: state.Mode.Modes,
  banker: state.auth.banker,
});

export default connect(mapStateToProps, { fetchingModes })(Modes);
