import {
  ADD_TENANT,
  FETCH_TENANTS,
  UPDATE_TENANT,
  DELETE_TENANT,
} from "../actions/types";

const initialState = {
  Tenants: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TENANTS:
      return { ...state, Tenants: action.payload };

    case ADD_TENANT:
      return { ...state, Tenants: [action.payload, ...state.Tenants] };

    case UPDATE_TENANT:
      var newTenants = [...state.Tenants];
      var oldBranch = newTenants.find(
        (tenant) => tenant.linker == action.payload.linker
      );
      var Index = newTenants.indexOf(oldBranch);
      newTenants.splice(Index, 1, action.payload);
      return {
        ...state,
        Tenants: [...newTenants],
      };
    case DELETE_TENANT:
      var newTenants = [...state.Tenants];

      return {
        ...state,
        Tenants: [
          ...newTenants.filter((tenant) => tenant.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
