import React, { useEffect } from "react";
import { appName, motto } from "../../constants/config";
import logo from "../../images/zidi-capital-sacco-logo.jfif";
import { withRouter } from "react-router-dom";
import AuthForm from "./AuthForm";
import { connect } from "react-redux";
import { updateViewed } from "../../actions/viewedActions";

const Auth = (props) => {
  useEffect(() => {
    props.updateViewed("app");
  }, []);

  return (
    <div>
      <nav class="navbar fixed-top navbar-light bg-primary">
        <a class="navbar-brand">
          <div className="row">
            <div className="col-4 d-flex justify-content-end">
              <img
                src={logo}
                width="50"
                height="50"
                class="d-inline-block rounded-pill"
                alt="Zidi-Capital-Sacco-Logo"
              />{" "}
            </div>
            <div className="col-8 pl-4">
              <p
                className="text-light text-center my-0"
                style={{ fontSize: 16 }}
              >
                Zidi Capital Sacco
              </p>{" "}
              <p
                className="text-light text-center small my-0"
                style={{ fontSize: 12 }}
              >
                Inspire And Empower{" "}
              </p>
            </div>
            <div></div>
          </div>
        </a>
      </nav>

      <div style={{ paddingTop: "40px" }}>
        <p className="text-primary h6">Welcome To Zidi Capital Sacco Portal</p>
        <AuthForm LoginType={"Member Sign In"}></AuthForm>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateViewed })(withRouter(Auth));
