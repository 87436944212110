import {
  login,
  logout,
  ADD_CREDENTIAL,
  ACTIVATE_CREDENTIAL,
  UPDATE_CREDENTIALS,
  ADD_STRUCTURE_BLOCK,
  UPDATE_STRUCTURE_BLOCK,
  REMOVE_STRUCTURE_BLOCK,
} from "../actions/types";

const initialState = {
  banker: { banker: {} },
  activeAuthorization: { bank: {} },
};

const UpdateAuthorizations = (newAuthorization, banker) => ({
  ...banker,
  affliates: [
    newAuthorization,
    ...banker.affliates.filter(
      (affliate) => affliate.id != newAuthorization.id
    ),
  ],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case login:
      return { ...state, banker: action.payload };
    case logout:
      return { ...state, banker: action.payload };
    case ADD_CREDENTIAL:
      let newAuthorizations = state.banker.affliates
        ? [action.payload, ...state.banker.affliates]
        : [action.payload];
      var newBanker = {
        ...state.banker,
        affliates: newAuthorizations,
      };

      return {
        ...state,
        banker: newBanker,
      };
    case ACTIVATE_CREDENTIAL:
      return { ...state, activeAuthorization: action.payload };
    case ADD_STRUCTURE_BLOCK:
      var blocks = [
        action.payload.data,
        ...state.activeAuthorization.bank[action.payload.block],
      ];
      var newActiveAuthorization = {
        ...state.activeAuthorization,
        bank: {
          ...state.activeAuthorization.bank,
          [action.payload.block]: blocks,
        },
      };
      var newBanker = UpdateAuthorizations(
        newActiveAuthorization,
        state.banker
      );

      return {
        ...state,
        banker: newBanker,
        activeAuthorization: newActiveAuthorization,
      };

    case UPDATE_STRUCTURE_BLOCK:
      var blocks = [
        action.payload.data,
        ...state.activeAuthorization.bank[action.payload.block],
      ];
      var newBlocks = blocks.filter(
        (block) => block.linker != action.payload.data.linker
      );
      newBlocks.unshift(action.payload.data);
      var newActiveAuthorization = {
        ...state.activeAuthorization,
        bank: {
          ...state.activeAuthorization.bank,
          [action.payload.block]: newBlocks,
        },
      };
      var newBanker = UpdateAuthorizations(
        newActiveAuthorization,
        state.banker
      );

      return {
        ...state,
        banker: newBanker,
        activeAuthorization: newActiveAuthorization,
      };
    case REMOVE_STRUCTURE_BLOCK:
      var blocks = [
        action.payload.data,
        ...state.activeAuthorization.bank[action.payload.block],
      ];
      var newBlocks = blocks.filter(
        (block) => block.linker != action.payload.data.linker
      );

      var newActiveAuthorization = {
        ...state.activeAuthorization,
        bank: {
          ...state.activeAuthorization.bank,
          [action.payload.block]: newBlocks,
        },
      };
      var newBanker = UpdateAuthorizations(
        newActiveAuthorization,
        state.banker
      );

      return {
        ...state,
        banker: newBanker,
        activeAuthorization: newActiveAuthorization,
      };

    case UPDATE_CREDENTIALS:
      var newBanker = {
        ...state.banker,
        affliates: [
          action.payload,
          ...state.banker.affliates.filter(
            (affliate) => affliate.id != action.payload.id
          ),
        ],
      };

      return {
        ...state,
        banker: newBanker,
      };
    default:
      return state;
  }
};
