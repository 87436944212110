import {
  ADD_REPAYMENT,
  FETCH_REPAYMENTS,
  UPDATE_REPAYMENT,
  DELETE_REPAYMENT,
} from "./types";

export const fetchingRepayments = (repayments) => (dispatch) => {
  dispatch({ type: FETCH_REPAYMENTS, payload: repayments });
};

export const addingRepayment = (repayment) => (dispatch) => {
  dispatch({ type: ADD_REPAYMENT, payload: repayment });
};

export const updatingRepayment = (repayment) => (dispatch) => {
  dispatch({ type: UPDATE_REPAYMENT, payload: repayment });
};

export const deletingRepayment = (repaymentId) => (dispatch) => {
  dispatch({ type: DELETE_REPAYMENT, payload: repaymentId });
};
