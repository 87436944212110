import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchingLandlords } from "../../actions/LandlordActions";
import { fetchingStaffs } from "../../actions/staffActions";
import { fetchingBuildings } from "../../actions/buildingActions";
import { fetchingModes } from "../../actions/modeActions";
import { fetchingTenants } from "../../actions/tenantActions";
import { fetchingHouses } from "../../actions/houseActions";
import { fetchingExpenses } from "../../actions/expenseActions";
import { fetchingInvoices } from "../../actions/invoiceActions";
import { fetchingRepayments } from "../../actions/repaymentActions";
import { fetchingPayments } from "../../actions/paymentActions";
import { app } from "../../constants/config";
import swal from "sweetalert";
import HomeNavItem from "./HomeNavItem";
import {
  faUserLock,
  faBuilding,
  faHouseUser,
  faHouseDamage,
  faUsers,
  faMoneyCheck,
  faReceipt,
  faMoneyBill,
  faMoneyCheckAlt,
  faArchive,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

const InstitutionHome = (props) => {
  useEffect(() => {
    FetchStructure("staff", "get");
    FetchStructure("building", "get");
    FetchStructure("mode", "get");
    FetchStructure("landlord", "get");
    FetchStructure("house", "get");
    FetchStructure("tenant", "get");
    FetchStructure("expense", "month");
    FetchStructure("invoice", "month");
    FetchStructure("payment", "month");
    FetchStructure("repayment", "month");
  }, []);

  const FetchStructure = (type, extent) => {
    fetch(`${app}/${type}/${extent}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getTime(),
        toDate:
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              0
            ).getDate(),
        month: `${new Date().getFullYear()}-${(
          "0" +
          (new Date().getMonth() + 1)
        ).slice(-2)}`,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code == 2) {
        } else {
          return type == "landlord"
            ? props.fetchingLandlords(details)
            : type == "staff"
            ? props.fetchingStaffs(details)
            : type == "building"
            ? props.fetchingBuildings(details)
            : type == "mode"
            ? props.fetchingModes(details)
            : type == "tenant"
            ? props.fetchingTenants(details)
            : type == "house"
            ? props.fetchingHouses(details)
            : type == "expense"
            ? props.fetchingExpenses(details)
            : type == "payment"
            ? props.fetchingPayments(details)
            : type == "repayment"
            ? props.fetchingRepayments(details)
            : type == "invoice"
            ? props.fetchingInvoices(details)
            : null;
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="row justify-content-center">
        {props.banker.credentials
          .map((credential) => credential.admin)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"staffs"}
              name="Staffs"
              count={props.staffs.length}
              sideBarToggle={props.sideBarToggle}
              icon={faUserLock}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.admin)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"modes"}
              name="Payment Modes"
              count={props.Modes.length}
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyCheckAlt}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.landlords)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"landlords"}
              name="Landlords"
              count={props.Landlords.length}
              sideBarToggle={props.sideBarToggle}
              icon={faHouseUser}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.buildings)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"buildings"}
              name="Buildings"
              count={props.Buildings.length}
              sideBarToggle={props.sideBarToggle}
              icon={faBuilding}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.houses)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"houses"}
              name="Houses"
              count={props.Houses.length}
              sideBarToggle={props.sideBarToggle}
              icon={faHouseDamage}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.tenants)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"tenants"}
              count={props.Tenants.length}
              name="Tenants"
              sideBarToggle={props.sideBarToggle}
              icon={faUsers}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.expenses)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"expenses"}
              name="Expenses"
              count={props.Expenses.length}
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyCheck}
            ></HomeNavItem>
          </div>
        ) : null}

        {props.banker.credentials
          .map((credential) => credential.expenses)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"invoices"}
              name="Invoices"
              count={props.Invoices.length}
              sideBarToggle={props.sideBarToggle}
              icon={faReceipt}
            ></HomeNavItem>
          </div>
        ) : null}
        {props.banker.credentials
          .map((credential) => credential.expenses)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"payments"}
              name="Tenants Payments"
              count={props.Payments.length}
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyBill}
            ></HomeNavItem>
          </div>
        ) : null}
        {/*props.banker.credentials
          .map((credential) => credential.landlords)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"repayments"}
              name="Landlord Repayments"
              count={props.Repayments.reduce((a, b) => +a + +b.amount, 0)}
              sideBarToggle={props.sideBarToggle}
              icon={faMoneyCheckAlt}
            ></HomeNavItem>
          </div>
          ) : null*/}
        {props.banker.credentials
          .map((credential) => credential.reports)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"rental"}
              name="Rental Report"
              sideBarToggle={props.sideBarToggle}
              icon={faArchive}
            ></HomeNavItem>
          </div>
        ) : null}
        {props.banker.credentials
          .map((credential) => credential.reports)
          .includes("1") ? (
          <div className="col-12 col-md-4  homeNavItem">
            <HomeNavItem
              url={"financial"}
              name="Financial Report"
              sideBarToggle={props.sideBarToggle}
              icon={faBook}
            ></HomeNavItem>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  Landlords: state.Landlord.Landlords,
  Buildings: state.Building.Buildings,
  Modes: state.Mode.Modes,
  Houses: state.House.Houses,
  staffs: state.staff.staffs,
  Invoices: state.Invoice.Invoices,
  Expenses: state.Expense.Expenses,
  Payments: state.Payment.Payments,
  Repayments: state.Repayment.Repayments,
});

export default connect(mapStateToProps, {
  fetchingLandlords,
  fetchingBuildings,
  fetchingHouses,
  fetchingStaffs,
  fetchingTenants,
  fetchingExpenses,
  fetchingInvoices,
  fetchingPayments,
  fetchingRepayments,
  fetchingModes,
})(withRouter(InstitutionHome));
