import React from "react";

const NoInvoices = () => {
  return (
    <div className="jumbotron justify-content-center">
      <h3 className="h3 text-center text-primary"> No Invoices</h3>
    </div>
  );
};

export default NoInvoices;
