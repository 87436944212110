import React from "react";
import { appName, motto } from "../../constants/config";
import logo from "../../images/zidi-capital-sacco-logo.jfif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const Header = (props) => {
  return (
    <>
      <nav class="navbar fixed-top navbar-light bg-primary d-md-none d-lg-none">
        <span
          class="navbar-brand"
          onClick={() => {
            props.history.push("/");
          }}
        >
          <img
            src={logo}
            width="50"
            height="50"
            class="d-inline-block align-center rounded-pill mb-4"
            alt="Zidi-Capital-Sacco-Logo"
          />
          <div className="d-inline-block">
            {" "}
            <p className="text-light my-0" style={{ fontSize: 12 }}>
              Zidi Capital Sacco
            </p>{" "}
            <p
              className="text-light text-center small my-0"
              style={{ fontSize: 8 }}
            >
              Inspire And Empower{" "}
            </p>
          </div>
        </span>
        {props.display == "none" ? null : (
          <>
            <span
              class="bg-primary text-center"
              data-toggle="collapse"
              data-target="#auth"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                props.updateLogin("Member Sign In");
              }}
            >
              <FontAwesomeIcon className="text-light" icon={faUser} /> <br />
              <span className="text-light" style={{ fontSize: 9 }}>
                Member Sign In
              </span>{" "}
            </span>

            <span
              class="bg-primary text-center mr-2"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon className="text-light" icon={faBars} />
              <br />
              <span className="text-light" style={{ fontSize: 9 }}>
                Menu
              </span>{" "}
            </span>
          </>
        )}
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          {props.display == "none" ? null : (
            <>
              {" "}
              <ul class="navbar-nav">
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/")}
                >
                  <a class="nav-link text-light">Home</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/about")}
                >
                  <a class="nav-link text-light">About</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/membership")}
                >
                  <a class="nav-link text-light">Membership</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/products")}
                >
                  <a class="nav-link text-light">Products</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/loan-calc")}
                >
                  <a class="nav-link text-light">Loan Calculator</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/gallery")}
                >
                  <a class="nav-link text-light">Gallery</a>
                </li>
                <li
                  class="nav-item text-light"
                  onClick={() => props.history.push("/jobs")}
                >
                  <a class="nav-link text-light">Jobs And Careers</a>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>
      <div className="bg-primary headerContainer  d-none d-sm-none d-md-block d-lg-block">
        <div className="row header justify-content-around">
          <div className="col-1 col-md-3">
            <img
              src={logo}
              className="float-left logo-image"
              alt="zidi-capiatl-sacco-logo"
              onClick={() => {
                props.history.push("/");
              }}
            />
          </div>
          <div className="col-6 col-md-6 p-0 m-0">
            <p className="text-center ml-2">
              <span className="title text-capitalize">{appName}</span> <br />
              <span className="subtitle text-capitalize">{motto}</span>
            </p>
          </div>
          <div className="col-5 col-md-3 mt-2 mx-0 px-0">
            <div className="d-flex justify-content-around">
              {props.display == "none" ? null : (
                <>
                  {" "}
                  {props.updateLogin ? (
                    <>
                      <span
                        className="btn-link nav-link text-center mx-1"
                        data-toggle="collapse"
                        style={{ cursor: "pointer" }}
                        data-target="#auth"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                          props.updateLogin("Member Sign In");
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="text-light navLink"
                          icon={faUser}
                        />{" "}
                        <br />
                        <span className="text-light navLink mb-2  small">
                          Member Sign In
                        </span>{" "}
                      </span>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-around align-items-center mt-4 pt-4"
          style={{ width: "100vw", background: "rgb(141, 9, 141)" }}
        >
          {props.display == "none" ? null : (
            <>
              {" "}
              <span
                className="btn-link text-light navLink"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/")}
              >
                Home
              </span>
              <span
                className="btn-link text-light navLink"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/about")}
              >
                About
              </span>
              <span
                className="btn-link text-light navLink"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/membership")}
              >
                Membership
              </span>
              <span
                className="btn-link text-light navLink"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/products")}
              >
                Products
              </span>
              <span
                className="btn-link text-light navLink"
                style={{ cursor: "pointer" }}
                onClick={() => props.history.push("/loan-calc")}
              >
                Loan Calculator
              </span>
              <span class="btn-link text-light dropdown navLink">
                <span
                  class="nav-link dropdown-toggle pb-2"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  More
                </span>
                <div
                  class="dropdown-menu bg-primary"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <span
                    class="dropdown-item btn-link text-light navLink"
                    style={{ cursor: "pointer" }}
                    onClick={() => props.history.push("/gallery")}
                  >
                    Gallery
                  </span>
                  <span
                    class="dropdown-item btn-link text-light navLink"
                    style={{ cursor: "pointer" }}
                    onClick={() => props.history.push("/jobs")}
                  >
                    Jobs And Careers
                  </span>
                </div>
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Header);
