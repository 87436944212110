import {
  login,
  logout,
  ACTIVATE_CREDENTIAL,
  ADD_CREDENTIAL,
  UPDATE_CREDENTIALS,
} from "./types";

export const loggingIn = (banker) => (dispatch) => {
  dispatch({ type: login, payload: banker });
};

export const loggingOut = () => (dispatch) => {
  dispatch({ type: logout, payload: {} });
};

export const addingAuthorization = (authorization) => (dispatch) => {
  dispatch({ type: ADD_CREDENTIAL, payload: authorization });
};

export const activatingAuthorization = (authorization) => (dispatch) => {
  dispatch({ type: ACTIVATE_CREDENTIAL, payload: authorization });
};

export const updatingAuthorizations = (authorization) => (dispatch) => {
  dispatch({ type: UPDATE_CREDENTIALS, payload: authorization });
};
