import { VIEWED_FROM } from "../actions/types";

const initialState = {
  viewed: "web",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWED_FROM:
      return { ...state, viewed: action.payload };
    default:
      return state;
  }
};
