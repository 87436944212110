import React, { useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../../constants/config";
import { fetchingStaffs } from "../../../actions/staffActions";
import AddStaffs from "./AddStaffs";
import DisplayStaffs from "./DisplayStaffs";
import NoStaffs from "./NoStaffs";

const Staffs = (props) => {
  useEffect(() => {
    fetchRemoteStaffs();
  }, []);

  const fetchRemoteStaffs = () => {
    fetch(`${app}/staff/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((details) => {
        if (details.code != 2) {
          props.fetchingStaffs(details);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Connection Error!",
          text: "Please try again",
          icon: "error",
          timer: 3000,
        });
      });
  };
  return (
    <div>
      <AddStaffs></AddStaffs>
      {props.staffs.length > 0 ? <DisplayStaffs /> : <NoStaffs />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeAuthorization: state.auth.activeAuthorization,
  banker: state.auth.banker,
  staffs: state.staff.staffs,
});

export default connect(mapStateToProps, { fetchingStaffs })(Staffs);
