import React from "react";
import { app } from "../../constants/config";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import logo from "../../images/houselogo.jpg";
import { appName, motto } from "../../constants/config";
import Pops from "../Pops/Pops";

const InstitutionHeader = (props) => {
  const { url } = useRouteMatch();

  return (
    <div className="bg-primary headerContainer">
      <div className="row bankHeader justify-content-center">
        <div className="col-12 text-center d-flex justify-content-between">
          <div className="float-left ml-2 my-2" style={{ width: "10%" }}>
            <div onClick={props.sideBarToggle}>
              <div className="menu"></div>
              <div className="menu"></div>
              <div className="menu"></div>
            </div>
          </div>
          <div style={{ width: "80%" }}>
            <p className="text-center">
              {" "}
              <span className="bankTitle text-capitalize">{appName}</span>
              <br />
              <span className="bankSubtitle text-capitalize">{motto}</span>
            </p>
          </div>

          <div style={{ display: "inline-block", width: "10%" }}>
            <img
              src={logo}
              className="float-right logo-image"
              alt="zidi-capital-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {})(InstitutionHeader);
