import {
  ADD_TENANT,
  FETCH_TENANTS,
  UPDATE_TENANT,
  DELETE_TENANT,
} from "./types";

export const fetchingTenants = (tenants) => (dispatch) => {
  dispatch({ type: FETCH_TENANTS, payload: tenants });
};

export const addingTenant = (tenant) => (dispatch) => {
  dispatch({ type: ADD_TENANT, payload: tenant });
};

export const updatingTenant = (tenant) => (dispatch) => {
  dispatch({ type: UPDATE_TENANT, payload: tenant });
};

export const deletingTenant = (tenantId) => (dispatch) => {
  dispatch({ type: DELETE_TENANT, payload: tenantId });
};
