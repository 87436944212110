import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Download from "../Download/Download";
import { app } from "../../constants/config";
import swal from "sweetalert";

const Summary = (props) => {
  const [Loading, setLoading] = useState(false);
  const [Expense, setExpense] = useState({ totalAmount: 0 });
  const [Invoice, setInvoice] = useState({ totalAmount: 0 });
  const [Payment, setPayment] = useState({ totalAmount: 0 });

  const [StartDate, setStartDate] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );
  const [EndDate, setEndDate] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate()}`
  );

  useEffect(() => {
    $("#summary").modal("show");
  }, []);

  const fetchData = () => {
    fetchSummary("expense");
    fetchSummary("invoice");
    fetchSummary("payment");
  };

  const fetchSummary = (type) => {
    setLoading(true);
    fetch(`${app}/summary/${type}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          StartDate.split("-")[0],
          StartDate.split("-")[1] - 1,
          StartDate.split("-")[2]
        ).getTime(),
        toDate: new Date(
          EndDate.split("-")[0],
          EndDate.split("-")[1] - 1,
          EndDate.split("-")[2]
        ).getTime(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details[0]) {
          if (type == "payment") {
            $("#summary").modal("hide");
          }
          return type == "expense"
            ? setExpense(details[0])
            : type == "invoice"
            ? setInvoice(details[0])
            : type == "payment"
            ? setPayment(details[0])
            : null;
        } else {
          swal({
            title: "Server Error!",
            span: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          span: "Check your connection and  try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <button
            onClick={() => $("#summary").modal("show")}
            className="btn btn-sm btn-primary btn-block rounded-pill"
          >
            Select Summary Actions
          </button>
        </div>
      </div>
      <div
        id={`summary`}
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <p className="text-center h6 text-primary">Select Time Period</p>
            </div>
            <div>
              <>
                <div className="card">
                  <label>Select Start Date</label>
                  <input
                    type="date"
                    value={StartDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="card">
                  <label>Select End Date</label>
                  <input
                    type="date"
                    value={EndDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </>
            </div>
            <div className="d-flex justify-content-around my-2">
              <button
                className="btn btn-sm btn-danger rounded-pill"
                onClick={() => $("#summary").modal("hide")}
              >
                Cancel
              </button>
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : (
                <button
                  className="btn btn-sm btn-primary rounded-pill"
                  onClick={() => fetchData()}
                >
                  View
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6" id="summaryDiv">
          <p className="text-center h6 text-primary">
            {StartDate} To {EndDate} Summary
          </p>
          <div>
            <p>
              <strong>Invoices :</strong> <em>{Invoice.totalAmount || 0}</em>
            </p>
            <p>
              <strong>Payments :</strong> <em>{Payment.totalAmount || 0}</em>
            </p>
            <p>
              <strong>Expenses :</strong> <em>{Expense.totalAmount || 0}</em>
            </p>
          </div>
        </div>

        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
          <Download
            rootElementId={"summaryDiv"}
            downloadFileName={`${StartDate}-to-${EndDate}-summary`}
          ></Download>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {})(Summary);
