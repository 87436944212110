import {
  ADD_PAYMENT,
  FETCH_PAYMENTS,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
} from "../actions/types";

const initialState = {
  Payments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS:
      return { ...state, Payments: action.payload };

    case ADD_PAYMENT:
      return { ...state, Payments: [action.payload, ...state.Payments] };

    case UPDATE_PAYMENT:
      var newPayments = [...state.Payments];
      var oldBranch = newPayments.find(
        (payment) => payment.linker == action.payload.linker
      );
      var Index = newPayments.indexOf(oldBranch);
      newPayments.splice(Index, 1, action.payload);
      return {
        ...state,
        Payments: [...newPayments],
      };
    case DELETE_PAYMENT:
      var newPayments = [...state.Payments];

      return {
        ...state,
        Payments: [
          ...newPayments.filter((payment) => payment.linker != action.payload),
        ],
      };

    default:
      return state;
  }
};
