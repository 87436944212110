import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingRepayment,
  updatingRepayment,
  deletingRepayment,
  fetchingRepayments,
} from "../../actions/repaymentActions";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";

const AddRepayments = (props) => {
  const [Repayments, setRepayments] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");

  const FetchRepayments = () => {
    setLoading(true);
    fetch(`${app}/repayment/month`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        fromDate: new Date(
          Month.split("-")[0],
          Month.split("-")[1] - 1,
          1
        ).getTime(),
        toDate:
          new Date(Month.split("-")[0], Month.split("-")[1] - 1, 1).getTime() +
          1000 *
            60 *
            60 *
            24 *
            new Date(Month.split("-")[0], Month.split("-")[1], 0).getDate(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 2) {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        } else {
          props.fetchingRepayments(details);
          $("#fetchRepayments").modal("hide");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const updateRepayments = (value, index, field) => {
    Repayments[index] = { ...Repayments[index], [field]: value };
    setRepayments([...Repayments]);
  };

  const RemoveRepayment = (index) => {
    let newRepayments = [...Repayments];
    newRepayments.splice(index, 1);
    setRepayments([...newRepayments]);
  };

  const AddRepaymentnput = () => {
    setRepayments([...Repayments, {}]);
  };

  const GetLandlord = (landlordId) => {
    let landlord = props.Landlords.find(
      (landlord) => landlord.id == landlordId
    );
    return landlord ? landlord : { name: "none", id: 0, housesId: 0 };
  };

  const SubmitRepayments = (Repayment) => {
    setSaving(true);
    fetch(`${app}/repayment/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Repayment,
        linker: Date.now(),
        houses_id: GetLandlord(Repayment.landlords_id).houses_id,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingRepayment(details);
          setRepayments([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SaveRepayments = () => {
    Repayments.forEach((Repayment, index) => {
      if (/\S/.test(Repayment)) {
        SubmitRepayments({
          ...Repayment,
        });
      }
    });
  };

  const RepaymentOutput = Repayments.map((Repayment, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemoveRepayment(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              <label>Landlord :</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) =>
                  updateRepayments(e.target.value, index, "landlords_id")
                }
              >
                <option value={0}>Select Landlord</option>
                {props.Landlords.map((landlord) => (
                  <option key={landlord.id} value={landlord.id}>
                    {landlord.name}
                  </option>
                ))}
              </select>
              <label>For Month</label>
              <input
                type="month"
                className="rounded-pill form-control"
                placeholder="Select Month"
                onChange={(e) =>
                  updateRepayments(e.target.value, index, "month")
                }
              />
              <label>Repayment Mode</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) =>
                  updateRepayments(e.target.value, index, "mode")
                }
              >
                <option value="cash">Select Repayment Mode</option>
                <option value="cash">Cash</option>
                <option value="bank">Bank</option>
                <option value="electronic">Electronic/M-pesa</option>
              </select>{" "}
              <label>Amount</label>
              <input
                placeholder="Enter Amount To Repayment"
                value={Repayment.amount}
                onChange={(e) =>
                  updateRepayments(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "amount"
                  )
                }
                className="rounded-pill form-control"
              />
              <label className="mt-2">Repayment Other Details</label>
              <textarea
                placeholder="Enter Repayment Details"
                value={Repayment.details}
                onChange={(e) =>
                  updateRepayments(e.target.value, index, "details")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div>
      <div className="d-flex justify-content-around">
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => $("#fetchRepayments").modal("show")}
        >
          View Repayments
        </button>
        {Repayments.length < 1 ? (
          <button
            className="btn-sm btn-info rounded-pill"
            onClick={AddRepaymentnput}
          >
            Add Repayment
          </button>
        ) : null}
      </div>
      {RepaymentOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : Repayments.length > 0 ? (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SaveRepayments}
            >
              Save
            </button>
          ) : null}
        </div>
      </div>
      <div
        id="fetchRepayments"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">View Repayments</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Select Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>

                <div className="col-12 d-flex justify-content-around my-4">
                  <button
                    className="btn-xs btn-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!Loading ? (
                    <button
                      className="btn-xs btn-primary rounded-pill"
                      onClick={() => FetchRepayments()}
                    >
                      View
                    </button>
                  ) : (
                    <span className="spinner-border text-primary"></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Landlords: state.Landlord.Landlords,
  activeAuthorization: state.auth.activeAuthorization,
});

export default connect(mapStateToProps, {
  addingRepayment,
  updatingRepayment,
  deletingRepayment,
  fetchingRepayments,
})(AddRepayments);
