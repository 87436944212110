import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { app } from "../../constants/config";
import { fetchingPayments } from "../../actions/paymentActions";
import DisplayPayments from "../Payments/DisplayPayments";
import NoPayments from "../Payments/NoPayments";
import AddPayments from "../Payments/AddPayments";

const HousePayments = (props) => {
  useEffect(() => {
    FetchPayments();
  }, [window.location.href.split("/").pop()]);

  const [Loading, setLoading] = useState(false);

  const FetchPayments = () => {
    setLoading(true);
    fetch(`${app}/payment/house`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        houseId: window.location.href.split("/").pop(),
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);

        if (details.code != 2) {
          props.fetchingPayments(details);
        } else {
          props.fetchingPayments([]);
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        props.fetchingPayments([]);
        setLoading(false);
        swal({
          title: "Connection Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const GetHouse = (houseId) => {
    let house = props.Houses.find((house) => house.id == houseId);
    return house ? house : { name: "none", id: 0 };
  };

  const Status = Loading ? (
    <>
      <p className="text-center">
        <span className="spinner-border text-primary "></span>
      </p>
    </>
  ) : props.Payments.length > 0 ? (
    <DisplayPayments />
  ) : (
    <NoPayments />
  );

  return (
    <>
      <p className="h6 text-center text-primary">
        <strong>
          {GetHouse(window.location.href.split("/").pop()).name} Payments
        </strong>
      </p>
      {GetHouse(window.location.href.split("/").pop()).tenants ? (
        GetHouse(window.location.href.split("/").pop())
          .tenants.map((tenant) => tenant.status)
          .includes("1") ? (
          <AddPayments
            from={"houses"}
            tenants_id={
              GetHouse(window.location.href.split("/").pop()).tenants.find(
                (tenant) => tenant.status == 1
              ).id
            }
          ></AddPayments>
        ) : null
      ) : null}

      {Status}
    </>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Payments: state.Payment.Payments,
  Houses: state.House.Houses,
});

export default connect(mapStateToProps, {
  fetchingPayments,
})(HousePayments);
