import React from "react";
import { connect } from "react-redux";
import DisplayInvoices from "./DisplayInvoices";
import NoInvoices from "./NoInvoices";
import AddInvoices from "./AddInvoices";

const Invoices = (props) => {
  const Status =
    props.Invoices.length > 0 ? <DisplayInvoices /> : <NoInvoices />;

  return (
    <>
      <AddInvoices></AddInvoices>
      {Status}
    </>
  );
};
const mapStateToProps = (state) => ({
  Invoices: state.Invoice.Invoices,
});

export default connect(mapStateToProps, {})(Invoices);
