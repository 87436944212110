import React from "react";

const NoStaffs = () => {
  return (
    <div className="row justify-content-center jumbotron">
      <h3 className="text-primary h3"> There are no staffs</h3>
    </div>
  );
};

export default NoStaffs;
