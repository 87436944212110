import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addingPayment,
  updatingPayment,
  deletingPayment,
  fetchingPayments,
} from "../../actions/paymentActions";
import swal from "sweetalert";
import { app } from "../../constants/config";
import $ from "jquery";

const AddPayments = (props) => {
  const [Payments, setPayments] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Month, setMonth] = useState("");

  const FetchPayment = () => {
    setLoading(true);
    fetch(`${app}/payment/month`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        month: Month,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setLoading(false);
        if (details.code == 2) {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        } else {
          props.fetchingPayments(details);
          $("#fetchPayments").modal("hide");
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const updatePayments = (value, index, field) => {
    Payments[index] = { ...Payments[index], [field]: value };
    setPayments([...Payments]);
  };

  const RemovePayment = (index) => {
    let newPayments = [...Payments];
    newPayments.splice(index, 1);
    setPayments([...newPayments]);
  };

  const AddPaymentnput = () => {
    setPayments([...Payments, { tenants_id: props.tenants_id }]);
  };

  const GetTenant = (tenantId) => {
    let tenant = props.Tenants.find((tenant) => tenant.id == tenantId);
    return tenant ? tenant : { name: "none", id: 0, housesId: 0 };
  };

  const SubmitPayments = (Payment) => {
    setSaving(true);
    fetch(`${app}/payment/register`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.banker.token}`,
      },
      body: JSON.stringify({
        ...Payment,
        linker: Date.now(),
        houses_id: GetTenant(Payment.tenants_id).houses_id,
        landlords_id: props.banker.user.original.landlord
          ? props.banker.user.original.landlord.id
          : props.banker.credentials.length > 0
          ? props.banker.credentials[0].landlords_id
          : 0,
      }),
    })
      .then((res) => res.json())
      .then((details) => {
        setSaving(false);
        if (details.state != 2) {
          props.addingPayment(details);
          setPayments([]);
        } else {
          swal({
            title: "Server Error!",
            text: "Please try again",
            icon: "error",
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        setSaving(false);
        swal({
          title: "Network Error!",
          text: "Check your connection and try again",
          icon: "error",
          timer: 3000,
        });
      });
  };

  const SavePayments = () => {
    Payments.forEach((Payment, index) => {
      if (/\S/.test(Payment)) {
        SubmitPayments({
          ...Payment,
        });
      }
    });
  };

  const PaymentOutput = Payments.map((Payment, index) => {
    return (
      <div key={index} className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-end">
              <button
                className="btn btn-danger rounded-pill"
                onClick={() => RemovePayment(index)}
              >
                X
              </button>
            </div>
            <div className="card-body">
              {!props.from ? (
                <>
                  {" "}
                  <label>Tenant :</label>
                  <select
                    className="rounded-pill form-control"
                    onChange={(e) =>
                      updatePayments(e.target.value, index, "tenants_id")
                    }
                  >
                    <option value={0}>Select Tenant</option>
                    {props.Tenants.map((tenant) => (
                      <option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}
              <label>Payment For</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) => updatePayments(e.target.value, index, "for")}
              >
                <option value="rent">Select Particular</option>
                <option value="deposit">Deposit</option>
                <option value="rent">Rent</option>
                <option value="water">Water Bill</option>
                <option value="electricity">Electricity Bill</option>
                <option value="expenses">Other Expenses</option>
              </select>
              <label>For Month</label>
              <input
                type="month"
                className="rounded-pill form-control"
                placeholder="Select Month"
                onChange={(e) => updatePayments(e.target.value, index, "month")}
              />
              <label>Payment Mode</label>
              <select
                className="rounded-pill form-control"
                onChange={(e) => updatePayments(e.target.value, index, "mode")}
              >
                <option value="cash">Select Payment Mode</option>
                {props.Modes.map((mode) => (
                  <option value={mode.name}>{mode.name}</option>
                ))}
                <option value="cash">Cash</option>
                <option value="bank">Bank</option>
                <option value="electronic">Electronic/M-pesa</option>
              </select>{" "}
              <label>Amount</label>
              <input
                placeholder="Enter Amount To Payment"
                value={Payment.amount}
                onChange={(e) =>
                  updatePayments(
                    e.target.value.slice(-1) == "-" ||
                      e.target.value.slice(-1) == "." ||
                      !isNaN(e.target.value.slice(-1))
                      ? e.target.value
                      : e.target.value.slice(0, -1),
                    index,
                    "amount"
                  )
                }
                className="rounded-pill form-control"
              />
              <label className="mt-2">Payment Other Details</label>
              <textarea
                placeholder="Enter Payment Details"
                value={Payment.details}
                onChange={(e) =>
                  updatePayments(e.target.value, index, "details")
                }
                className="rounded-pill form-control"
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="justify-content-center">
      <div className="d-flex justify-content-around">
        <button
          className="btn-sm btn-primary rounded-pill"
          onClick={() => $("#fetchPayments").modal("show")}
        >
          View Payments
        </button>
        {Payments.length < 1 ? (
          <button
            className="btn-sm btn-info rounded-pill"
            onClick={AddPaymentnput}
          >
            Add Repayment
          </button>
        ) : null}
      </div>
      {PaymentOutput}
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6 d-flex justify-content-around">
          {Saving ? (
            <p className="text-center">
              <span className="spinner-border text-primary "></span>
            </p>
          ) : Payments.length > 0 ? (
            <button
              className="btn-sm btn-primary rounded-pill"
              onClick={SavePayments}
            >
              Save
            </button>
          ) : null}
        </div>
      </div>
      <div
        id="fetchPayments"
        className="modal fade"
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: 5000 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="text-center capitalize">View Payments</h5>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-6 text-center">
                <div className="card card-primary">
                  <label>Select Month</label>
                  <input
                    type="month"
                    className="rounded-pill form-control"
                    placeholder="Select Month"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>

                <div className="col-12 d-flex justify-content-around my-4">
                  <button
                    className="btn-xs btn-danger rounded-pill"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {!Loading ? (
                    <button
                      className="btn-xs btn-primary rounded-pill"
                      onClick={() => FetchPayment()}
                    >
                      View
                    </button>
                  ) : (
                    <span className="spinner-border text-primary"></span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banker: state.auth.banker,
  Tenants: state.Tenant.Tenants,
  activeAuthorization: state.auth.activeAuthorization,
  Modes: state.Mode.Modes,
});

export default connect(mapStateToProps, {
  addingPayment,
  updatingPayment,
  deletingPayment,
  fetchingPayments,
})(AddPayments);
