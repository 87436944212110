import {
  ADD_MODE,
  FETCH_MODES,
  UPDATE_MODE,
  DELETE_MODE,
} from "../actions/types";

const initialState = {
  Modes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MODES:
      return { ...state, Modes: action.payload };

    case ADD_MODE:
      return { ...state, Modes: [action.payload, ...state.Modes] };

    case UPDATE_MODE:
      var newModes = [...state.Modes];
      var oldBranch = newModes.find(
        (MODEs) => MODEs.linker == action.payload.linker
      );
      var Index = newModes.indexOf(oldBranch);
      newModes.splice(Index, 1, action.payload);
      return {
        ...state,
        Modes: [...newModes],
      };
    case DELETE_MODE:
      var newModes = [...state.Modes];

      return {
        ...state,
        Modes: [...newModes.filter((MODEs) => MODEs.linker != action.payload)],
      };

    default:
      return state;
  }
};
